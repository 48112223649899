import React, { useEffect, useState } from "react";
import "./uploadContent.scss";
import { Helmet } from "react-helmet";
import api from "../../../Config/api";
import toast, { Toaster } from "react-hot-toast";
import RTEUpload from "../../../Components/SunEditor/suneditor_upload";
import axios from "axios";
import {
  Box,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

const UploadContent = () => {
  const subId = Number(localStorage.getItem("subject_id"));
  const userDetails = JSON.parse(localStorage.getItem("userData"));

  const [tempChapterId, setTempChapterId] = useState("");
  const [clearRTE, setClearRTE] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingChapter, setLoadingChapter] = useState(false);
  const [chaptersList, setChaptersList] = useState([]);
  const [tag, setTag] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  //chapter details calling
  const chapterDetails = async () => {
    setLoadingChapter(true);
    const response = await axios.get(
      `${api.chapter.list}${subId}?page=1&limit=100`,
    );
    if (response?.data?.chaptersFound) {
      setChaptersList(response?.data?.chapters);
      setLoadingChapter(false);
    } else {
      setChaptersList([]);
      toast.dismiss();
      toast(
        (t) => (
          <span className="toast-cont">
            <div>Please add new chapter to this subject</div>
            <div className="toastBtn">
              <button onClick={() => toast.dismiss(t.id)}>Close</button>
            </div>
          </span>
        ),
        { duration: 5000 },
      );
      setLoadingChapter(false);
    }
  };

  useEffect(() => {
    chapterDetails();
  }, []);

  const submitContent = async () => {
    setLoading(true);
    if (tempChapterId > 0) {
      if (tag.length > 0) {
        if (title && title.length > 5) {
          if (content && content.length >= 2) {
            const response = await axios.post(api.topic.create, {
              chapterId: tempChapterId,
              tag: tag,
              title: title,
              content: content,
              facultyId: userDetails.id,
            });
            if (response?.data?.topicCreated) {
              setTitle("");
              setContent("");
              toast.dismiss();
              toast.success("Topic uploaded successfully");
              setClearRTE(true);
              setLoading(false);
            } else {
              toast.dismiss();
              setLoading(false);
              toast.error("Failed to upload data. Please try again");
            }
          } else {
            toast.dismiss();
            setLoading(false);
            toast.error("Enter topic description");
          }
        } else {
          toast.dismiss();
          setLoading(false);
          toast.error("Enter a valid title");
        }
      } else {
        toast.dismiss();
        setLoading(false);
        toast.error("Select a tag");
      }
    } else {
      toast.dismiss();
      setLoading(false);
      toast.error("Select a chapter");
    }
  };

  return (
    <div className="uc-cont">
      <Helmet>
        <title>DreamCatchr | Add Topic</title>
        <meta name="description" content="Add Topic" />
      </Helmet>
      <div>
        <Toaster position="top-center" reverseOrder={false} />
      </div>
      <div className="uc-left">
        <div className="uc-body">
          <div className="uc-hd">Add Topic</div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: { xs: "column", lg: "row" },
              gap: 2,
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                Select Chapter
              </Typography>
              {loadingChapter ? (
                <Skeleton height={40} width={"100%"} />
              ) : (
                <TextField
                  select
                  value={tempChapterId}
                  onChange={(e) => {
                    setTempChapterId(Number(e.target.value));
                  }}
                  fullWidth
                  variant="outlined"
                >
                  {chaptersList?.map((item) => (
                    <MenuItem
                      onClick={() => setTempChapterId(item?.id)}
                      value={item?.id}
                    >
                      {item?.chapter_name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Box>
            <Box sx={{ width: "100%" }}>
              <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                Select Tag
              </Typography>
              <TextField select value={tag} fullWidth variant="outlined">
                {[
                  "Diagram",
                  "Explanation",
                  "Problem",
                  "Experiment",
                  "Short Note",
                  "Points To Remember",
                  "Interesting Facts",
                ].map((item) => (
                  <MenuItem onClick={() => setTag(item)} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Box>

          <Box mt={1.5}>
            <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
              Title
            </Typography>
            <TextField
              value={title}
              type="text"
              placeholder="Enter Title"
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              fullWidth
              variant="outlined"
            />
          </Box>

          <Box mt={1.5}>
            <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
              Content
            </Typography>
            <Box
              id="rte-cont"
              sx={{
                border: "1px solid #aaa",
                borderRadius: 2,
                overflow: "hidden",
              }}
            >
              <RTEUpload setValue={setContent} close={clearRTE} />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              mt: 2,
              mb: 10,
            }}
          >
            <LoadingButton
              loading={loading}
              onClick={submitContent}
              fullWidth={false}
              variant="contained"
            >
              Submit
            </LoadingButton>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default UploadContent;
