import { useState, useEffect } from "react";
import { EditableMathField } from "react-mathquill";
import "./index.scss";

const MathQuillAnswerEdit = ({ value, setValue }) => {
  const [phone, setPhone] = useState(window.innerWidth < 1100 ? true : false);

  const changeWidth = (e) => {
    if (phone) {
      e.__controller.container[0].style.width = "100%";
    } else {
      e.__controller.container[0].style.width = "60%";
      // if(e.__controller.container[0].parentNode.className == 'pop-box-edit-ans') {
      //   e.__controller.container[0].parentNode.parentNode.style.width = '100%'
      //   e.__controller.container[0].parentNode.parentNode.style.backgroundColor = 'red'
      //   console.log('edit answer: ',e.__controller.container[0].parentNode.parentNode)
      // }
    }
  };

  return (
    <EditableMathField
      className="mathquill-container"
      latex={value}
      onChange={(e) => {
        setValue(e.latex());
      }}
      style={{ width: "max-content" }}
      mathquillDidMount={changeWidth}
    />
  );
};

export default MathQuillAnswerEdit;
