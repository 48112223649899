const base_url = process.env.REACT_APP_BASE_URL;

export default {
  auth: {
    login: base_url + "faculty/login",
  },

  user: {
    details: base_url + "faculty/details/", // teacherId
    update: base_url + "faculty/update-profile/", // userId,
    notifications: base_url + "faculty/notifications/", // userId
  },

  subject: {
    list: base_url + "faculty/get-subject/", // subjectId
  },

  chapter: {
    list: base_url + "faculty/chapters/", //subjectId
    create: base_url + "faculty/create-chapter",
    update: base_url + "faculty/update-chapter",
    delete: base_url + "faculty/delete-chapter/", // chapterId
  },

  topic: {
    list: base_url + "faculty/topics/", //chaptedId,
    details: base_url + "faculty/topics-details/", //topicId,
    create: base_url + "faculty/create-topic",
    update: base_url + "faculty/update-topic",
    delete: base_url + "faculty/delete-topic/", // topicId
    tags: base_url + "faculty/topic-tags/", // subjectId
  },

  question: {
    list: base_url + "faculty/questions",
    create: base_url + "faculty/create-question",
    update: base_url + "faculty/update-question",
    delete: base_url + "faculty/delete-question/", // questionId
  },

  // teacher_chat_last_five: base_url + "teacher_chat_last_five/?id=",
  faq: base_url + "faculty/faqs",
  report: base_url + "faculty/report-issue",
};
