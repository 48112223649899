import React, { useState, useEffect } from "react";
import "./edit-question-page.scss";
import styled from "styled-components";

import "../../../../../node_modules/suneditor/dist/css/suneditor.min.css";
import toast from "react-hot-toast";
import PopUpUQ from "../../../../Components/PopupUQ/popup-uq";
import api from "../../../../Config/api";
import { access_key } from "../../../../Config/util";

import RTEEdit from "../../../../Components/SunEditor/suneditor_edit";

import MathQuillQuestionEdit from "../../../../Components/MathQuill/question-edit";
import MathQuillAnswerEdit from "../../../../Components/MathQuill/answer-edit";
import MathQuillAnswerShow from "../../../../Components/MathQuill/answer-show";
import axios from "axios";
import { Box, Button, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const EditQuestionPage = ({ data, showEdit, edit, showMap, refresh }) => {
  const [tempTitle, setTempTitle] = useState(data.title);
  const [tempDes, setTempDes] = useState(data.subtitle);
  const [tempLevel, setTempLevel] = useState(data.level);
  const [tempAnsKey, setTempAnsKey] = useState("");
  const [tempAnsSt, setTempAnsSt] = useState("false");
  const [tempAnsId, setTempAnsId] = useState(0);
  const [correctAnsSel, setCorrectAnsSel] = useState(false);
  const [ansSet, setAnsSet] = useState(JSON.parse(data.options));
  const [tempAnsDes, setTempAnsDes] = useState(data.solution);
  const [popUp, setPopUp] = useState(false);
  const [tempEditId, setTempEditId] = useState();
  const [submitting, setSubmitting] = useState(false);

  // const userDetails = JSON.parse(localStorage.getItem("userData"));
  // const subId = Number(localStorage.getItem("subject_id"));

  // console.log(data)

  const addToAnsList = () => {
    if (tempAnsKey === "") {
      toast.dismiss();
      toast.error("Enter some value");
    } else {
      setAnsSet([
        ...ansSet,
        {
          // id: tempAnsId,
          // key: tempAnsId,
          value: tempAnsKey,
          isAnswer: tempAnsSt,
        },
      ]);
      for (let i = 0; i < ansSet.length; i++) {
        ansSet[i].id = i;
        ansSet[i].key = i;
      }
      // setTempAnsId(tempAnsId + 1);
      setTempAnsKey("");
    }
  };

  

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      if (tempTitle && tempTitle.length > 5) {
        if (ansSet && ansSet.length >= 2) {
          if (correctAnsSel) {
            try {
              const response = await axios.patch(api.question.update, {
                questionId: data.id,
                title: tempTitle,
                subtitle: tempDes,
                solution: tempAnsDes,
                level: tempLevel,
                options: JSON.stringify(ansSet),
              });
              if (response?.data?.questionUpdated) {
                toast.success(response?.data?.message);
                refresh();
                showMap(true);
                showEdit(false);
                setSubmitting(false);
              } else {
                setSubmitting(false);
                toast.dismiss();
                toast.error("Something went wrong...");
              }
            } catch (error) {
              toast.dismiss();
              setSubmitting(false);
            }
          } else {
            toast.dismiss();
            toast.error("Enter a atleast one correct answer");
          }
        } else {
          toast.dismiss();
          toast.error("Enter a valid answer or atleast two answer keys");
        }
      } else {
        toast.dismiss();
        toast.error("Enter a valid title");
      }
      setSubmitting(false);
      toast.dismiss();
    } catch (error) {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    ansSet.map((d) => {
      if (d.isAnswer === "true") {
        setTempAnsSt("false");
        setCorrectAnsSel(true);
      }
    });
  }, [ansSet]);

  return (
    <div id="edit-Page_Container">
      <div id="uq-hd">Edit Questions</div>
      <div id="uq-body">
        {/* <div id="q-title">
          <h4>Title</h4>
          <input
            type="text"
            value={tempTitle}
            placeholder="Enter title"
            onChange={(e) => {
              setTempTitle(e.target.value);
            }}
          />
        </div> */}

        <Box mb={2}>
          <Typography variant="subtitle2" fontWeight={700}>Question</Typography>
          <TextField
            sx={{mt: 0.5}}
            value={tempTitle}
            type="text"
            placeholder="Enter Title"
            onChange={(e) => {
              setTempTitle(e.target.value);
            }}
            fullWidth
            variant="outlined"
          />
        </Box>

        <div id="sub-q">
          <h4>Question's Explanation (optional)</h4>
          <RTEEdit value={tempDes} setValue={setTempDes} />
        </div>

        <div id="q-level">
          <h4>Select Level</h4>
          <div className="q-level-sel-cont">
            <select
              className="q-level-sel"
              name="topic"
              value={tempLevel}
              onChange={(e) => {
                setTempLevel(e.target.value);
              }}
            >
              <option value="Easy">Easy</option>
              <option value="Medium">Medium</option>
              <option value="Hard">Hard</option>
            </select>
          </div>
        </div>

        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', mb: 1, columnGap: 1}}>
          <Box sx={{width: '100%', flex: 1}}>
            <Typography variant="subtitle2" fontWeight={700}>Add Answers</Typography>
            <Box>
              <TextField
                sx={{mt: 0.5}}
                value={tempAnsKey}
                type="text"
                placeholder="Enter answer option"
                onChange={(e) => {
                  setTempAnsKey(e.target.value);
                }}
                fullWidth={true}
                variant="outlined"
              />
            </Box>
          </Box>
          <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', columnGap: 1}}>
            <Button disable={correctAnsSel} variant="contained" sx={{bgcolor: 'green'}} onClick={() => setTempAnsSt(tempAnsSt === 'true' ? 'false' : 'true')}>Correct Answer</Button>
            <Button onClick={addToAnsList} variant="contained">Add</Button>
          </Box>
        </Box>

        <div id="uq-display-answer">
          {true &&
            ansSet.map((i, index) => {
              return (
                <div className="uq-ans-set">
                  <div className="uq-ans-id">
                    <span>{index + 1}</span>
                  </div>

                  <div className="uq-ans-value">
                    <MathQuillAnswerShow value={i.value} />
                  </div>

                  <div className="uq-ans-setts">
                    <button
                      className="uq-ans-del"
                      onClick={() => {
                        let data = ansSet.filter((k) => {
                          if (k.isAnswer === "true") {
                            setCorrectAnsSel(false);
                          }
                          return k.id != i.id;
                        });
                        setAnsSet(data);
                      }}
                    >
                      Delete
                    </button>

                    <button
                      className="uq-ans-editAns"
                      onClick={() => {
                        setPopUp(true);
                        setTempEditId({
                          id: i.id,
                          value: i.value,
                        });
                      }}
                    >
                      Edit
                    </button>

                    {popUp && (
                      <PopUpUQ
                        setPopUp={setPopUp}
                        onClose={() => {
                          setTempEditId();
                        }}
                        defaultValue={tempEditId.value}
                        updateAnswer={(val) => {
                          ansSet.filter((p) => {
                            return p.id === tempEditId.id
                              ? (p.value = val)
                              : null;
                          });
                          console.log(ansSet);
                        }}
                      />
                    )}

                    {i.isAnswer === "true" ? (
                      <span className="uq-ans-isAnswer">Correct answer</span>
                    ) : (
                      <span
                        className="uq-ans-setAnswer"
                        onClick={() => {
                          const filteredAnsSet = ansSet.filter((l) =>
                            l.id === i.id
                              ? (l.isAnswer = "true")
                              : (l.isAnswer = "false"),
                          );
                          setCorrectAnsSel(true);
                          setAnsSet(filteredAnsSet);
                        }}
                      >
                        Set as correct answer
                      </span>
                    )}
                  </div>
                </div>
              );
            })}
        </div>

        <div className="sub-q" id="uq-answer-description">
          <h4>Answer's Explanation (optional)</h4>
          <RTEEdit value={tempAnsDes} setValue={setTempAnsDes} />
        </div>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            columnGap: 1,
            mt: 2,
            mb: 10,
          }}
        >
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              showMap(true);
              showEdit(false);
            }}
          >
            Discard
          </Button>
          <LoadingButton
            loading={submitting}
            onClick={handleSubmit}
            variant="contained"
          >
            Submit
          </LoadingButton>
        </Box>
      </div>
    </div>
  );
};

export default EditQuestionPage;

const AnsCont = styled.div`
  justify-content: space-between;
  @media (max-width: 1100px) {
    justify-content: ${(props) =>
      !props.disp ? "flex-start" : "space-between"};
  }
`;

const AddBtn = styled.button`
  @media (max-width: 1100px) {
    margin-top: ${(props) => (!props.mg ? "0.5rem" : "0")};
    background-color: red;
    margin-left: 0.5rem;
  }
`;

const CorrectAns = styled.div`
  background-color: ${(props) => (props.bg === "true" ? "#6ac001" : "#eee")};
  color: ${(props) => (props.bg === "true" ? "white" : "black")};
`;

const Discard = styled.button`
  cursor: "pointer";
`;

const Submit = styled.button`
  cursor: ${(props) => (props.cur ? "progress" : "pointer")};
`;
