"use strict";

export const randomUUID = (val = 16) => {
  var d = new Date().getTime();
  if (window.performance && typeof window.performance.now === "function") {
    d += performance.now();
  }
  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      var r = (d + Math.random() * val) % val | 0;
      d = Math.floor(d / val);
      return (c == "x" ? r : (r & 0x3) | 0x8).toString(val);
    },
  );
  return uuid;
};

/**
 *
 * @param {*} val must be 4/6/8
 * @returns OTP value
 */
export const getOTP = (val = 4) => {
  let otp = 0;
  val === 4
    ? (otp = Math.floor(1000 + Math.random() * 9000))
    : val === 6
      ? (otp = Math.floor(100000 + Math.random() * 900000))
      : val === 8
        ? (otp = Math.floor(10000000 + Math.random() * 90000000))
        : (otp = Math.floor(100000 + Math.random() * 900000));
  return otp;
};

/**
 *
 * @param {*} len must be 4/6/8
 * @returns OTP value
 */
export const randomString = (len) => {
  var p = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  return [...Array(len)].reduce((a) => a + p[~~(Math.random() * p.length)], "");
};

export const validEmail = (email) => {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};
