import React from "react";
import "./noti_all_shimmer.scss";

const NOALShim = () => {
  return (
    <div className="noal-shim-cont">
      <div className="noal-shim-img-cont">
        <div className="noal-shim-imgs"></div>
      </div>
      <div className="noal-shim-textt">
        <div className="noal-shim-textt-lineone"></div>
        <div className="noal-shim-textt-linetwo"></div>
        <div className="noal-shim-textt-linetwo"></div>
      </div>
    </div>
  );
};

const NotificationAllShimmer = () => {
  return (
    <div className="noal-shim-all-cont">
      <NOALShim />
      <NOALShim />
      <NOALShim />
      <NOALShim />
      <NOALShim />
      <NOALShim />
    </div>
  );
};

export default NotificationAllShimmer;
