import React, { useEffect, useState } from "react";
import SunEditor from "suneditor-react";
import katex from "katex";
// import '../../../node_modules/suneditor/dist/css/suneditor.min.css'
import "./suneditor.min.css";
import "katex/dist/katex.min.css";

const RTEEdit = ({ value, setValue }) => {
  const [des, setDes] = useState(value);

  // console.log(des);

  useEffect(() => {
    setValue(des);
  }, [des]);

  return (
    <SunEditor
      height="12rem"
      placeholder="Enter Description. Make use of full screen mode while typing"
      setDefaultStyle="font-family: popReg; font-size: 0.85rem;"
      setOptions={{
        katex: katex,
        imageMultipleFile: true,
        imageAccept: ".png,.svg,.jpg,.jpeg",
        template: [{ name: "title-1" }],
        buttonList: [
          ["undo", "redo"],
          ["font", "fontSize", "formatBlock"],
          ["paragraphStyle", "blockquote", "math"],
          ["fontColor", "hiliteColor", "textStyle"],
          ["removeFormat"],
          ["table", "link", "image", "video", "audio"],
          ["bold", "underline", "italic", "strike", "subscript", "superscript"],
          // '/',
          ["outdent", "indent"],
          ["align", "horizontalRule", "list", "lineHeight"],
          ["fullScreen", "showBlocks"],
          ["preview"],
          // ['save', 'template'],
          // ['dir', 'dir_ltr', 'dir_rtl']
        ],
      }}
      setContents={des}
      onChange={(e) => {
        setDes(e);
      }}
      toggleFullScreen={(e) => {
        if (e) {
          document.querySelector(`.sidenav-cont`).style.zIndex = "1";
          document.querySelector(`.topnav-cont`).style.zIndex = "1";
        } else {
          document.querySelector(`.sidenav-cont`).style.zIndex = "12";
          document.querySelector(`.topnav-cont`).style.zIndex = "12";
        }
      }}
    />
  );
};

export default RTEEdit;
