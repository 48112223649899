import React, { useEffect, useState } from "react";
import SunEditor from "suneditor-react";
import katex from "katex";
// import '../../../node_modules/suneditor/dist/css/suneditor.min.css'
import "./suneditor.min.css";

const RTEUpload = ({ setValue, close }) => {
  const [des, setDes] = useState("");

  // useEffect(() => {
  //   setValue(des)
  // },[des])

  useEffect(() => {
    if (close) {
      setDes("");
    }
  }, [close]);

  return (
    <SunEditor
      class
      height="12rem"
      placeholder="Enter Description. Make use of full screen mode while typing"
      setDefaultStyle="font-family: popReg; font-size: 0.85rem;z-index: 10000;"
      setOptions={{
        katex: katex,
        imageMultipleFile: true,
        imageAccept: ".jpg",
        template: [{ name: "title-1" }],
        buttonList: [
          ["undo", "redo"],
          ["font", "fontSize", "formatBlock"],
          ["paragraphStyle", "blockquote", "math"],
          ["fontColor", "hiliteColor", "textStyle"],
          ["removeFormat"],
          ["table", "link", "image", "video", "audio"],
          ["bold", "underline", "italic", "strike", "subscript", "superscript"],
          ["outdent", "indent"],
          ["align", "horizontalRule", "list", "lineHeight"],
          ["fullScreen", "showBlocks"],
          ["preview"],
        ],
      }}
      setContents={des}
      onChange={(e) => {
        setDes(e);
        setValue(e);
      }}
      toggleFullScreen={(e) => {
        if (e) {
          document.querySelector(`.sidenav-cont`).style.zIndex = "1";
          document.querySelector(`.topnav-cont`).style.zIndex = "1";
        } else {
          document.querySelector(`.sidenav-cont`).style.zIndex = "12";
          document.querySelector(`.topnav-cont`).style.zIndex = "12";
        }
      }}
      onImageUploadBefore={(files, info) => {
        console.log(files, info);
      }}
    />
  );
};

export default RTEUpload;
