import { createSlice } from "@reduxjs/toolkit";

export const pageState = createSlice({
  name: "page",
  initialState: {
    value: {
      pageSt: "scrnOne",
    },
  },
  reducers: {
    setPageTo: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setPageTo } = pageState.actions;

export default pageState.reducer;
