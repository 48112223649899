import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "./sidenav.scss";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { changeTo } from "../../../Features/menuState";
import { setPageTo } from "../../../Features/pageState";

//images
import dashBoard from "../../../Images/Icons/Home/Dashboard.svg";
import uploadQ from "../../../Images/Icons/Home/Add_question.svg";
import uploadC from "../../../Images/Icons/Home/Add_content.svg";
import editQ from "../../../Images/Icons/Home/Edit_question.svg";
import editC from "../../../Images/Icons/Home/Edit_content.svg";
import report from "../../../Images/Icons/Home/Report.svg";
import support from "../../../Images/Icons/Home/Support.svg";
import message from "../../../Images/Icons/Home/Message.svg";
import data from "../../../Config/data";

const Sidenav = () => {
  const nav = useNavigate();
  const disp = useDispatch();

  const menus = useSelector((state) => state.menu.value);
  const pages = useSelector((state) => state.page.value);

  useEffect(() => {
    disp(changeTo({ menuSt: "-15rem" }));
  }, [pages]);

  useEffect(() => {
    if (pages.pageSt === "scrnOne") nav("home/", { replace: true });
  }, [pages]);

  const chngOne = () => {
    disp(setPageTo({ pageSt: "scrnOne" }));
    nav("home/", { replace: true });
    chng();
  };
  const chngTwo = () => {
    disp(setPageTo({ pageSt: "scrnTwo" }));
    nav("home/create-chapter", { replace: true });
    chng();
  };
  const chngFour = () => {
    disp(setPageTo({ pageSt: "scrnFour" }));
    nav("home/add-topic", { replace: true });
    chng();
  };
  const chngSix = () => {
    disp(setPageTo({ pageSt: "scrnSix" }));
    nav("home/edit-topic", { replace: true });
    chng();
  };
  const chngThree = () => {
    disp(setPageTo({ pageSt: "scrnThree" }));
    nav("home/add-question");
    chng();
  };
  const chngFive = () => {
    disp(setPageTo({ pageSt: "scrnFive" }));
    nav("home/edit-question", { replace: true });
    chng();
  };
  const chngSeven = () => {
    disp(setPageTo({ pageSt: "scrnSeven" }));
    nav("home/messages", { replace: true });
    chng();
  };
  const chngEight = () => {
    disp(setPageTo({ pageSt: "scrnEight" }));
    nav("home/report", { replace: true });
    chng();
  };
  const chngNine = () => {
    disp(setPageTo({ pageSt: "scrnNine" }));
    nav("home/support", { replace: true });
    chng();
  };

  const chng = () => {
    const one = document.querySelector(`#one`);
    const a = one.getAttribute("class");
    const two = document.querySelector(`#two`);
    const three = document.querySelector(`#three`);

    if (menus.menuSt === "-15rem") {
      one.setAttribute("class", "line openOne");
      two.setAttribute("class", "line openTwo");
      three.setAttribute("class", "line openThree");
    } else {
      one.setAttribute("class", "line closeOne");
      two.setAttribute("class", "line closeTwo");
      three.setAttribute("class", "line closeThree");
    }
  };

  return (
    <Cont ht={menus.menuSt} className="sidenav-cont">
      <div className="sidenav-middle">
        <div className="sidenav-cont-two">
          <div className="sn-set" onClick={chngOne}>
            <span
              className={
                pages.pageSt === "scrnOne" ? "side-bar-selected" : "side-bar"
              }
            ></span>
            <img className="sn-logo" src={dashBoard} alt="logo" />
            <span>Dashboard</span>
          </div>

          <div className="sn-set" onClick={chngTwo}>
            <span
              className={
                pages.pageSt === "scrnTwo" ? "side-bar-selected" : "side-bar"
              }
            ></span>
            <img className="sn-logo" src={uploadC} alt="logo" />
            <span>Create Chapter</span>
          </div>

          <div className="sn-set" onClick={chngFour}>
            <span
              className={
                pages.pageSt === "scrnFour" ? "side-bar-selected" : "side-bar"
              }
            ></span>
            <img className="sn-logo" src={uploadC} alt="logo" />
            <span>Add Topics</span>
          </div>

          <div className="sn-set" onClick={chngSix}>
            <span
              className={
                pages.pageSt === "scrnSix" ? "side-bar-selected" : "side-bar"
              }
            ></span>
            <img className="sn-logo" src={editC} alt="logo" />
            <span>Edit Topics</span>
          </div>

          <div className="sn-set" onClick={chngThree}>
            <span
              className={
                pages.pageSt === "scrnThree" ? "side-bar-selected" : "side-bar"
              }
            ></span>
            <img className="sn-logo" src={uploadQ} alt="logo" />
            <span>Add Questions</span>
          </div>

          <div className="sn-set" onClick={chngFive}>
            <span
              className={
                pages.pageSt === "scrnFive" ? "side-bar-selected" : "side-bar"
              }
            ></span>
            <img className="sn-logo" src={editQ} alt="logo" />
            <span>Edit Questions</span>
          </div>

          <div className="sn-set" onClick={chngSeven}>
            <span
              className={
                pages.pageSt === "scrnSeven" ? "side-bar-selected" : "side-bar"
              }
            ></span>
            <img className="sn-logo" src={message} alt="logo" />
            <span>Messages</span>
          </div>
        </div>
      </div>

      <div className="sidenav-foot">
        <h3 className="footer-hd">Other Options</h3>

        <div className="sn-set" onClick={chngEight}>
          <span
            className={
              pages.pageSt === "scrnEight" ? "side-bar-selected" : "side-bar"
            }
          ></span>
          <img className="sn-logo" src={report} alt="logo" />
          <span>Report an issue</span>
        </div>

        <div className="sn-set" onClick={chngNine}>
          <span
            className={
              pages.pageSt === "scrnNine" ? "side-bar-selected" : "side-bar"
            }
          ></span>
          <img className="sn-logo" src={support} alt="logo" />
          <span>Support</span>
        </div>
      </div>
    </Cont>
  );
};
export default Sidenav;

const Cont = styled.div`
  left: ${(props) => props.ht};
  @media (min-width: 900px) {
    left: 0;
  }
`;
