export const palette = {
  mode: "light",
  primary: {
    main: "#2576ef",
    light: "#5091f2",
    dark: "#1952a7",
  },
  secondary: {
    main: "#651fff",
    light: "#834bff",
    dark: "#4615b2",
  },
  // primary: {
  //   main: "#2b3ffe",
  //   light: "#834bff",
  //   dark: "#4615b2",
  // },
  // secondary: {
  //   main: "#651fff",
  //   light: "#7fc6ae",
  //   dark: "#42806b",
  // },
  success: {
    main: "#72bf44",
    light: "#8ecb69",
    dark: "#4f852f",
  },
  error: {
    main: "#ff1744",
    light: "#ff4569",
    dark: "#b2102f",
  },
  warning: {
    main: "#ec743b",
    light: "#ef8f62",
    dark: "#a55129",
  },
  info: {
    main: "#0284ae",
    light: "#349cbe",
    dark: "#015c79",
  },

  text: {
    primary: "#484848",
    secondary: "#65b9c6",
    disabled: "#00000090",
    hint: "#00000060",
  },
  background: {
    default: "#fff",
    paper: "#fdf9fb",
    // page: "#fff"
  },
  divider: "#00000010",
};
