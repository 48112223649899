import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

//styles
import "./Styles/messages.scss";
import "./Styles/all-chats.scss";
import "./Styles/chat-page.scss";
import styled from "styled-components";

import searchIcon from "../../../Images/Icons/search.svg";
import imgPlaceH from "../../../Images/Icons/userPlaceholder.png";
import chevDown from "../../../Images/Icons/chevron-down.svg";
import backArrow from "../../../Images/Icons/arrow_back.svg";

const EmptyPage = () => {
  return <div id="empty-chat">Select any chat</div>;
};

const ChatBoxShimmer = () => {
  return (
    <div className="individual-chat-box individual-chat-box-shim">
      <div className="chat-profile-img-box">
        <span className="chat-img-box-shimm"></span>
      </div>
      <div className="chat-msg-box">
        <h3 className="chat-msg-box-h3-shim"></h3>
        <p className="chat-msg-box-p-shim"></p>
      </div>
      <div className="chat-details-box">
        <span className="chat-time-shim"></span>
        <div className="chat-fun-number-box">
          <span className="chat-number"></span>
          <span className="chat-drop-down">
            <span className="chat-drop-down-shim" />
          </span>
        </div>
      </div>
    </div>
  );
};

const Messages = () => {
  const [isPhone, setIsPhone] = useState(false);
  const [isChatBox, setIsChatBox] = useState(true);
  const [isChatPage, setIsChatPage] = useState(true);
  useEffect(() => {
    if (window.innerWidth < 1100) {
      setIsPhone(true);
    }
  }, []);
  useEffect(() => {
    if (window.innerWidth < 1100) {
      setIsChatPage(false);
    }
  }, []);

  const ChatBox = () => {
    return (
      <div
        className="individual-chat-box"
        onClick={() => {
          if (isPhone) {
            setIsChatBox(false);
            setIsChatPage(true);
          }
        }}
      >
        <div className="chat-profile-img-box">
          <img src={imgPlaceH} />
        </div>
        <div className="chat-msg-box">
          <h3>Student Name</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt,
            deserunt.
          </p>
        </div>
        <div className="chat-details-box">
          <span className="chat-time">Today</span>
          <div className="chat-fun-number-box">
            <span className="chat-number">5</span>
            <span className="chat-drop-down">
              <img src={chevDown} />
            </span>
          </div>
        </div>
      </div>
    );
  };

  const ChatPage = () => {
    return (
      <div id="indi-chat-page-container">
        <div id="chat-page-profile-container">
          {isPhone && (
            <div
              id="chat-back-icon"
              onClick={() => {
                if (isPhone) {
                  setIsChatBox(true);
                  setIsChatPage(false);
                }
              }}
            >
              <img src={backArrow} />
            </div>
          )}
          <div id="chat-profile-img-container">
            <img src={imgPlaceH} />
          </div>
          <p>Student Name</p>
        </div>

        <div id="chat-display-container">
          <p>CHAT BUBBLES</p>
        </div>

        <div id="chat-page-text-input-container"></div>
      </div>
    );
  };

  return (
    <div id="messages-container">
      <Helmet>
        <title>DreamCatchr | Messages</title>
        <meta name="description" content="Messages" />
      </Helmet>
      {isChatBox && (
        <div id="all-chats-container">
          <div id="msg-search-container">
            <div id="msg-search-box">
              <img id="search-icon" src={searchIcon} />
              <input type="text" id="search-inp" placeholder="Search" />
            </div>
          </div>

          <div id="chats-map-container">
            <ChatBoxShimmer />
            <ChatBoxShimmer />
            <ChatBoxShimmer />
            <ChatBoxShimmer />
            <ChatBoxShimmer />
            <ChatBoxShimmer />
            <ChatBoxShimmer />
            <ChatBoxShimmer />
            <ChatBoxShimmer />
            <ChatBoxShimmer />
            <ChatBoxShimmer />
            <ChatBoxShimmer />
            <ChatBoxShimmer />
            <ChatBoxShimmer />
          </div>
        </div>
      )}

      {isChatPage && (
        <ChatPageCont
          wd={isPhone}
          id="chat-page-container"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <ChatPage /> */}
          COMING SOON
        </ChatPageCont>
      )}
    </div>
  );
};

export default Messages;

const ChatPageCont = styled.div`
  width: ${(props) => (props.wd ? "100%" : "67%")};
`;
