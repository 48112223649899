import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import api from "../../../../Config/api";
import { access_key } from "../../../../Config/util";
import "../../../../Components/PopupUQ/popup-uq.scss";
import axios from "axios";

const PopUpCCEdit = ({
  setShowEditPopUp,
  chapterDetails,
  defaultValue,
  onClose,
  facultyId,
}) => {
  const [vals, setVals] = useState(defaultValue.chapter_name);
  const [edit, setEdit] = useState(false);

  const submitTitle = async (name) => {
    setEdit(true);
    const response = await axios.patch(api.chapter.update, {
      chapterName: name,
      chapterId: defaultValue.id,
    });
    if (response?.data?.updated) {
      chapterDetails();
      toast.dismiss();
      toast.success(response?.data?.message);
      setEdit(false);
    } else {
      setEdit(false);
      toast.dismiss();
      toast.error(response?.data?.message);
    }
  };

  const onEdit = () => {
    if (vals && vals.length > 2) {
      toast.loading("updating.", {
        duration: 1000,
      });
      var time = setTimeout(() => {
        submitTitle(vals);
        setShowEditPopUp(false);
      }, 1000);
    } else {
      toast.error("Enter a valid title");
    }
    return () => clearTimeout(time);
  };

  return (
    <div id="create-chapter-popup-page">
      <Toaster position="top-center" reverseOrder={true} />
      <div id="pop-box-cont">
        <div>
          <label>Edit the title</label>
          <input
            type="text"
            placeholder="Enter title here"
            value={vals}
            onChange={(e) => {
              setVals(e.target.value);
            }}
          />
          <button
            id="pop-submit"
            onClick={onEdit}
            disabled={edit ? true : false}
          >
            Edit
          </button>
          <button
            id="pop-close-btn"
            onClick={() => {
              setShowEditPopUp(false);
              onClose();
            }}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopUpCCEdit;
