import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { Helmet } from "react-helmet";

// Styles
import "./login.scss";

// Images
import userIcon from "../../Images/Icons/user.svg";
import lockIcon from "../../Images/Icons/lock.svg";

// API and Config
import api from "../../Config/api";
import { APP_VERSION } from "../../Config/config";
import data from "../../Config/data";

const Login = () => {
  const [userName, setUserName] = useState("");
  const [userPass, setUserPass] = useState("");
  const [isWaiting, setIsWaiting] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("isLogged") === "true") {
      data.user_details = localStorage.getItem("userData");
      navigate("/home", { replace: true });
    }
  }, [navigate]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        authenticateLogin();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [userPass]);

  const authenticateLogin = async () => {
    if (userName && userPass) {
      setIsWaiting(true);
      try {
        const response = await axios.post(api.auth.login, {
          username: userName,
          password: userPass,
        });

        if (response?.data?.loginSuccess) {
          toast.success("Login success. Please wait...");
          localStorage.setItem("isLogged", "true");
          localStorage.setItem("userData", JSON.stringify(response.data));

          const subjectIds = response.data.subject_id.split(",");
          const subjectId = Number(subjectIds[0]);
          localStorage.setItem("subject_id", subjectId);
          setSubjectInlocalStorage(subjectId);
        } else {
          toast.error(response?.data?.message || "Invalid User Details");
        }
      } catch (error) {
        const errorMessage =
          error.response?.data?.message ||
          "An error occurred. Please try again.";
        toast.error(errorMessage);
      } finally {
        setIsWaiting(false);
      }
    } else {
      toast.error("Invalid User Details");
    }
  };

  const setSubjectInlocalStorage = async (subId) => {
    try {
      const response = await axios.get(`${api.subject.list}${subId}`);
      if (response?.data?.subjectFound) {
        localStorage.setItem("subjectDetails", JSON.stringify(response.data));
        navigate("/home");
      } else {
        toast.error("Error occurred! Please wait");
      }
    } catch (error) {
      toast.error("Error fetching subject details");
    }
  };

  return (
    <div className="login-screen-cont">
      <Helmet>
        <title>DreamCatchr | Login Page</title>
      </Helmet>
      <Toaster position="top-center" reverseOrder={false} />

      <div className="inner-cont">
        <div className="icon-cont">
          <div className="dreamLogo" />
        </div>

        <div className="form-cont">
          <div className="form-head">
            <h1>Welcome Back</h1>
            <h3>Enter your credentials to access your account</h3>
          </div>

          <div className="form-inputs">
            <div className="username">
              <img src={userIcon} alt="userIcon" className="username-logo" />
              <input
                type="text"
                placeholder="Enter Username"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>

            <div className="password">
              <img src={lockIcon} alt="lockIcon" className="password-logo" />
              <input
                type="password"
                placeholder="Enter your password"
                value={userPass}
                onChange={(e) => setUserPass(e.target.value)}
              />
            </div>

            <button
              className="sign-in"
              onClick={isWaiting ? null : authenticateLogin}
              disabled={isWaiting}
            >
              {isWaiting ? "Please wait..." : "Sign In"}
            </button>
          </div>
        </div>

        <div className="fo-re-cont">
          <p>VERSION {APP_VERSION}</p>
        </div>
      </div>
    </div>
  );
};

export default Login;
