import React, { useState } from "react";
import toast from "react-hot-toast";
import api from "../../../Config/api";
import axios from "axios";
import {
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

export const TopicDelete = ({ open, setOpen, topic, refresh }) => {
  const [loading, setLoading] = useState(false);

  const deleteTopic = async () => {
    setLoading(true);
    const response = await axios.delete(`${api.topic.delete}${topic?.id}`);
    if (response?.data?.deleted) {
      setLoading(false);
      refresh();
      setOpen(false);
      toast.dismiss();
      toast.success("Chapter deleted successfully");
    } else {
      toast.dismiss();
      toast.error("Failed to delete data. Please try again");
      setLoading(false);
    }
  };

  return (
    <Dialog
      sx={{ zIndex: 2200 }}
      maxWidth="sm"
      fullWidth
      onClose={() => setOpen(false)}
      open={open}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            bgcolor: "#f0f0f0",
            justifyContent: "space-between",
            px: 2,
            pt: 1,
            pb: 1,
          }}
        >
          <Typography variant="subtitle1" fontWeight={600} fontSize={17}>
            Delete Topic?
          </Typography>
          <IconButton onClick={() => setOpen(false)} size="small">
            <Close />
          </IconButton>
        </Box>
        <Divider />
        <Box sx={{ px: 2, py: 2, pb: 3 }}>
          <Typography variant="body1">
            Are you sure you want to delete the topic{" "}
            <strong>{topic?.title}</strong>.
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            columnGap: 1.5,
            px: 2,
            py: 1.5,
            width: "100%",
          }}
        >
          <Button
            size="small"
            sx={{
              borderRadius: 2,
              px: 1.5,
              fontSize: 15,
              py: 0.5,
              width: "fit-content",
              color: "gray",
              border: "1px solid #ccc",
            }}
            onClick={() => setOpen(false)}
            variant="text"
          >
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            size="small"
            sx={{
              borderRadius: 2,
              px: 1.5,
              fontSize: 15,
              py: 0.5,
              width: "fit-content",
            }}
            onClick={() => deleteTopic()}
            variant="contained"
          >
            Delete
          </LoadingButton>
        </Box>
      </Box>
    </Dialog>
  );
};
