import React, { useEffect, useState, useContext } from "react";
import "./menu.scss";
import { useSelector, useDispatch } from "react-redux";
import { changeTo } from "../../../../Features/menuState.js";

const HamMenu = () => {
  // currentMenuStHamMenu: it changes on click. the updation
  const menus = useSelector((state) => state.menu.value);
  const dispatch = useDispatch();

  useEffect(() => {
    if (window.innerWidth < 1100) {
      dispatch(changeTo({ menuSt: "-15rem" }));
    }
  }, []);

  const chng = () => {
    const one = document.querySelector(`#one`);
    const a = one.getAttribute("class");
    const two = document.querySelector(`#two`);
    const three = document.querySelector(`#three`);

    if (menus.menuSt === "-15rem") {
      //its currently closed. need to open
      one.setAttribute("class", "line openOne");
      two.setAttribute("class", "line openTwo");
      three.setAttribute("class", "line openThree");
      dispatch(changeTo({ menuSt: "0" }));
    } else {
      //currently opened. need to close
      one.setAttribute("class", "line closeOne");
      two.setAttribute("class", "line closeTwo");
      three.setAttribute("class", "line closeThree");
      dispatch(changeTo({ menuSt: "-15rem" }));
    }
  };

  return (
    <div
      id="box"
      style={{ WebkitTapHighlightColor: "transparent" }}
      onClick={chng}
    >
      <div className="line " id="one"></div>
      <div className="line " id="two"></div>
      <div className="line " id="three"></div>
    </div>
  );
};

export default HamMenu;
