import React from "react";
import { Image, Shimmer } from "react-shimmer";
import "./recent-chats-shimmer.scss";

const RecentChatsShimmer = () => {
  return (
    <div className="data-table-shimmer">
      <span className="data-table-shimmer-span" id="data-table-one">
        <Shimmer
          duration={1500}
          height={25}
          width={80}
          className="data-table-shimmer-inner-span"
        />
      </span>
      <span className="data-table-shimmer-span" id="data-table-two">
        <Shimmer
          duration={1500}
          height={25}
          width={225}
          className="data-table-shimmer-inner-span"
        />
      </span>
      <span className="data-table-shimmer-span" id="data-table-three">
        <Shimmer
          duration={1500}
          height={25}
          width={225}
          className="data-table-shimmer-inner-span"
        />
      </span>
      <span className="data-table-shimmer-span" id="data-table-four">
        <Shimmer
          duration={1500}
          height={25}
          width={80}
          className="data-table-shimmer-inner-span"
        />
      </span>
      <span className="data-table-shimmer-span" id="data-table-five">
        <Shimmer
          duration={1500}
          height={25}
          width={80}
          className="data-table-shimmer-inner-span"
        />
      </span>
      <span className="data-table-shimmer-span" id="data-table-six">
        <Shimmer
          duration={1500}
          height={25}
          width={160}
          className="data-table-shimmer-inner-span"
        />
      </span>
      <span className="data-table-shimmer-span" id="data-table-seven">
        <Shimmer
          duration={1500}
          height={25}
          width={80}
          className="data-table-shimmer-inner-span"
        />
      </span>
      <span className="data-table-shimmer-span" id="data-table-eight">
        <Shimmer
          duration={1500}
          height={25}
          width={80}
          className="data-table-shimmer-inner-span"
        />
      </span>
    </div>
  );
};

export default RecentChatsShimmer;
