import React, { useState, useEffect, useRef } from "react";
import "./create-chapter.scss";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import api from "../../../Config/api";
import { access_key } from "../../../Config/util";
import toast, { Toaster } from "react-hot-toast";
import PopUpCCEdit from "./PopupCCEdit/popup-cc-edit";
import PopUpCCDel from "./PopupCCDel/popup-cc-delete";
import axios from "axios";
import { Box, Skeleton, Typography } from "@mui/material";

//random build

const CreateChapter = () => {
  const subId = Number(localStorage.getItem("subject_id"));
  const userDetails = JSON.parse(localStorage.getItem("userData"));

  const [tempChapterSet, setTempChapterSet] = useState([]);
  const [tempChapterId, setTempChapterId] = useState("");
  const [tempTitle, setTempTitle] = useState("");
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showEditPopUp, setShowEditPopUp] = useState(false);
  const [showDelPopUp, setShowDelPopUp] = useState(false);

  const chapterDetails = async () => {
    setLoading(true);
    const response = await axios.get(
      `${api.chapter.list}${subId}?page=1&limit=100`,
    );
    if (response?.data?.chaptersFound) {
      setTempChapterSet(response?.data?.chapters);
      setLoading(false);
    } else {
      setTempChapterSet([]);
      toast.dismiss();
      setLoading(false);
      toast(
        (t) => (
          <span className="toast-cont">
            <div>Please add new chapter to this subject</div>
            <div className="toastBtn">
              <button onClick={() => toast.dismiss(t.id)}>Close</button>
            </div>
          </span>
        ),
        { duration: 5000 },
      );
    }
  };

  useEffect(() => {
    chapterDetails();
  }, []);

  console.log("XXX",userDetails);
  

  const submitTitle = async () => {
    setSubmit(true);
    if (tempTitle && tempTitle.length > 4) {
      const response = await axios.post(api.chapter.create, {
        subjectId: subId,
        facultyId: userDetails.id,
        chapterName: tempTitle,
      });
      if (response?.data?.created) {
        setTempTitle("");
        chapterDetails();
        toast.dismiss();
        toast.success("Chapter uploaded successfully");
        setSubmit(false);
      } else {
        setSubmit(false);
        toast.dismiss();
        toast.error("Failed to upload data. Please try again");
      }
    } else {
      setSubmit(false);
      toast.dismiss();
      toast.error("Enter a valid title");
    }
  };

  const ChapterBox = ({ l, ind }) => {
    return (
      <div className="cc-chap-edit-indi-box">
        <div className="cc-chap-edit-index">
          {ind < 10 ? `${"0" + ind}` : ind}
        </div>
        <div className="cc-chap-edit-chapname">{l.chapter_name}</div>
        <div className="cc-chap-edit-buttons">
          <button
            className="cc-edit-btn-one"
            onClick={() => {
              setShowEditPopUp(true);
              setTempChapterId(l);
            }}
          >
            Edit
          </button>
          {showEditPopUp && (
            <PopUpCCEdit
              setShowEditPopUp={setShowEditPopUp}
              onClose={() => {
                setTempChapterId();
              }}
              defaultValue={tempChapterId}
              facultyId={userDetails.id}
              chapterDetails={() => {
                chapterDetails();
              }}
            />
          )}
        </div>
        <div className="cc-chap-edit-buttons">
          <button
            className="cc-edit-btn-two"
            onClick={() => {
              setShowDelPopUp(true);
              setTempChapterId(l.id);
            }}
          >
            Delete
          </button>
          {showDelPopUp && (
            <PopUpCCDel
              setShowDelPopUp={setShowDelPopUp}
              chapterId={tempChapterId}
              chapterDetailsFun={() => {
                chapterDetails();
                console.log("fetching chaptersa again : refreshing");
              }}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="create-chapter-container">
      <Helmet>
        <title>DreamCatchr | Create Chapter</title>
        <meta name="description" content="Chapters" />
      </Helmet>
      <div>
        <Toaster position="top-center" reverseOrder={false} />
      </div>
      <div className="cc-left">
        <div className="cc-body">
          <div className="cc-hd">Create Chapter</div>

          <div className="cc-body-topic-cont">
            <h4>Title</h4>
            <br />
            <input
              id="cc-ti"
              value={tempTitle}
              type="text"
              placeholder="Enter Title"
              onChange={(e) => {
                setTempTitle(e.target.value);
              }}
            />
          </div>

          <div id="cc-submit-btna">
            <Submit
              cur={submit}
              disabled={submit ? true : false}
              onClick={submitTitle}
            >
              Submit
            </Submit>
          </div>

          <div className="cc-hd" id="edit-chap-hd-container">
            Edit Chapters
          </div>
          <div id="edit-chaps-container">
            {loading ? (
              [1, 2, 3, 4].map((_, index) => (
                <Skeleton
                  key={index}
                  height={60}
                  width={"100%"}
                  sx={{ mb: 1 }}
                />
              ))
            ) : !loading && tempChapterSet?.length === 0 ? (
              <Box
                sx={{
                  py: 20,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="subtitle1" sx={{ color: "#ddd" }}>
                  No Chapters Found
                </Typography>
              </Box>
            ) : (
              tempChapterSet &&
              tempChapterSet.map((i, index) => (
                <ChapterBox l={i} ind={index + 1} />
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateChapter;

const Submit = styled.button`
  cursor: ${(props) => (props.cur ? "progress" : "pointer")};
`;
