import React, { useEffect, useState } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
// import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import { Helmet } from "react-helmet";
import toast, { Toaster } from "react-hot-toast";

import PreLoader from "./Components/Preloader/preloader";
import Login from "./Pages/Login/login";
import PassReset from "./Pages/Reset_pass/pass_reset";
import Home from "./Pages/Main/Home/home";

//pages
import Dashboard from "./Pages/Main/Dashboard/index";
import CreateChapter from "./Pages/Main/CreateChapter/create-chapter";
import UploadQuestion from "./Pages/Main/UploadQuestion/uploadQuestion";
import UploadContent from "./Pages/Main/UploadContent/uploadContent";
import EditQuestion from "./Pages/Main/EditQuestion/edit-question";
import EditContent from "./Pages/Main/EditContent/edit-content";
import Messages from "./Pages/Main/Messages/messages";
import Report from "./Pages/Main/Report/report";
import Support from "./Pages/Main/Support/Support";
import EditAccount from "./Pages/Main/EditAccout/edit_account";
import NotificationAll from "./Pages/Main/NotificationAll/notification_all";
import { DisableAccess } from "./Functions/disable-access";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material";
import CustomTheme from "./theme/index";

const App = () => {
  // const basepath = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ?
  // "" :
  // "/teacher"

  useEffect(() => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      console.log("DEVELOPMENT MODE");
    } else {
      // DisableAccess();
    }
  }, []);

  const theme = createTheme({
    palette: CustomTheme?.palette,
    components: CustomTheme?.components,
    typography: CustomTheme?.typography,
    shadows: CustomTheme?.shadows,
  });

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />
        </Helmet>

        <Toaster />

        <CssBaseline />
        <Routes>
          <Route exact path="/" element={<PreLoader />} />
          <Route path="login" element={<Login />} />
          <Route path="password-reset" element={<PassReset />} />

          <Route path="*" element={<Home />}>
            <Route path="home/" element={<Dashboard />} />
            <Route path="home/create-chapter" element={<CreateChapter />} />
            <Route path="home/add-question" element={<UploadQuestion />} />
            <Route path="home/add-topic" element={<UploadContent />} />
            <Route path="home/edit-question" element={<EditQuestion />} />
            <Route path="home/edit-topic" element={<EditContent />} />
            <Route path="home/messages" element={<Messages />} />
            <Route path="home/report" element={<Report />} />
            <Route path="home/support" element={<Support />} />
            <Route path="home/edit-account" element={<EditAccount />} />
            <Route path="home/notification" element={<NotificationAll />} />
          </Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
