import React, { useState, useEffect } from "react";
import "./edit-question.scss";

import { Helmet } from "react-helmet";
import toast, { Toaster } from "react-hot-toast";
import api from "../../../Config/api";
import { access_key } from "../../../Config/util";
import EditQuestionPage from "./EditQuestionPage/edit-question-page";

import MathQuillQuestionShow from "../../../Components/MathQuill/question-show";
import axios from "axios";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { QuestionDelete } from "./question-delete";

const EditQuestion = () => {
  const [tempChapterSet, setTempChapterSet] = useState([]);
  const [tempChapterId, setTempChapterId] = useState("");
  const [tempTopicSet, setTempTopicSet] = useState("");
  const [tempTopicId, setTempTopicId] = useState(1);
  const [showChapter, setShowChapter] = useState(true);
  const [showTopic, setShowTopic] = useState(true);

  //news
  const [map, showMap] = useState(true);
  const [edit, showEdit] = useState(false);
  const [mapQuestionSet, setMapQuestionSet] = useState([]);
  const [selectedChapterData, setSelectedChapterData] = useState({});
  const [showDelPopUp, setShowDelPopUp] = useState(false);
  const initialState = { chapter: 0, topic: 0 };
  const [id, setId] = useState(initialState);
  const [message, setMessage] = useState("Select a chapter");
  const [showMessage, setShowMessage] = useState(true);

  const [loadingChapter, setLoadingChapter] = useState(false);
  const [chaptersList, setChaptersList] = useState([]);
  const [selectedChapter, setSelectedChapter] = useState(null);
  const [loadingTopic, setLoadingTopic] = useState(false);
  const [topicsList, setTopicsList] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState(null);

  const [loadingQuestion, setLoadingQuestion] = useState(false);
  const [questionsList, setQuestionsList] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const userDetails = JSON.parse(localStorage.getItem("userData"));
  const subId = Number(localStorage.getItem("subject_id"));

  const getChaptersList = async () => {
    setLoadingChapter(true);
    const response = await axios.get(
      `${api.chapter.list}${subId}?page=1&limit=100`,
    );
    if (response?.data?.chaptersFound) {
      setChaptersList(response?.data?.chapters);
      setLoadingChapter(false);
    } else {
      setChaptersList([]);
      toast.dismiss();
      toast(
        (t) => (
          <span className="toast-cont">
            <div>Please add new chapter to this subject</div>
            <div className="toastBtn">
              <button onClick={() => toast.dismiss(t.id)}>Close</button>
            </div>
          </span>
        ),
        { duration: 5000 },
      );
      setLoadingChapter(false);
    }
  };

  const getTopicsList = async (id) => {
    setLoadingTopic(true);
    const response = await axios.get(`${api.topic.list}${id}?page=1&limit=100`);
    if (response?.data?.message === "Topics found") {
      setLoadingTopic(false);
      setTopicsList(response?.data?.topics);
    } else {
      setTopicsList([]);
      toast.dismiss();
      toast(
        (t) => (
          <span className="toast-cont">
            <div>
              Failed to fetch topics, Please add new topics to this chapter
            </div>
            <div className="toastBtn">
              <button onClick={() => toast.dismiss(t.id)}>Close</button>
            </div>
          </span>
        ),
        { duration: 5000 },
      );
      setLoadingTopic(false);
      setQuestionsList([]);
    }
  };

  useEffect(() => {
    if (chaptersList?.length === 0) getChaptersList();
  }, []);

  const getQuestionList = async (id) => {
    setLoadingQuestion(true);
    try {
      const response = await axios.post(
        `${api.question.list}?page=1&limit=100`,
        {
          subjectId: subId,
          chapterId: selectedChapter?.id,
          topicId: Number(id),
        },
      );
      if (response?.data?.questions?.length > 0) {
        setQuestionsList(response?.data?.questions);
        setLoadingQuestion(false);
      } else {
        toast.dismiss();
        toast.error(
          "Failed to fetch questions, Please add new questions to this topic",
        );
        setQuestionsList([]);
        setLoadingQuestion(false);
      }
    } catch (error) {
      setLoadingQuestion(false);
    }
  };

  console.log(questionsList);

  // const QuestionMapBox = ({ l, ind }) => {
  //   return (
  //     <div className="eq-chap-edit-indi-box">
  //       <div className="eq-chap-edit-index">
  //         {ind < 10 ? `${"0" + ind}` : ind}
  //       </div>

  //       {/* <div className="eq-chap-edit-chapname">{l.title}</div> */}
  //       {/* <RTESunText defVal={l.title}/> */}
  //       <div className="eq-chap-edit-chapname">
  //         <MathQuillQuestionShow value={l.title}/>
  //       </div>

  //       <div className="eq-chap-edit-buttons">
  //         <button
  //           className="eq-edit-btn-one"
  //           onClick={() => {
  //             showMap(false);
  //             showEdit(true);
  //             setSelectedChapterData(l)
  //           }}
  //         >
  //           Edit
  //         </button>
  //       </div>
  //       <div className="eq-chap-edit-buttons">
  //         <button
  //           className="eq-edit-btn-two"
  //           onClick={() => {
  //             setSelectedChapterData(l);
  //             setShowDelPopUp(true);
  //           }}
  //         >
  //           Delete
  //         </button>
  //         {/* {showDelPopUp && (
  //           <PopUpEQDel
  //             setShowDelPopUp={setShowDelPopUp}
  //             chapterId={selectedChapterData.id}
  //             chapterDetailsFun={() => {
  //               getChaptersList();
  //               setShowDelPopUp(false);
  //             }}
  //           />
  //         )} */}
  //       </div>
  //     </div>
  //   );
  // };

  return (
    <Box id="eq-cont" sx={{ height: "100%", width: "100%" }}>
      <Helmet>
        <title>DreamCatchr | Edit Question</title>
        <meta name="description" content="Edit Question" />
      </Helmet>
      <div>
        <Toaster position="top-center" reverseOrder={false} />
      </div>
      {map && (
        <Box id="eq-left">
          <Typography id="uq-hd" color="primary" variant="h6">
            Edit Questions
          </Typography>
          <Box mt={2} id="eq-body" sx={{ pb: 10 }}>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                flexDirection: { xs: "column", lg: "row" },
                gap: 2,
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                  Select Chapter
                </Typography>
                {loadingChapter ? (
                  <Skeleton height={40} width={"100%"} />
                ) : (
                  <TextField
                    select
                    value={selectedChapter?.id}
                    fullWidth
                    variant="outlined"
                  >
                    {chaptersList?.map((item) => (
                      <MenuItem
                        onClick={() => {
                          setSelectedChapter(item);
                          getTopicsList(item?.id);
                        }}
                        value={item?.id}
                      >
                        {item?.chapter_name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </Box>
              <Box sx={{ width: "100%" }}>
                <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                  Select Topic
                </Typography>
                {loadingTopic ? (
                  <Skeleton height={40} width={"100%"} />
                ) : !loadingTopic && selectedChapter === null ? (
                  <TextField
                    disabled
                    fullWidth
                    select
                    label="Select A Chapter First"
                  />
                ) : (
                  <TextField
                    select
                    value={selectedTopic?.id}
                    fullWidth
                    variant="outlined"
                  >
                    {topicsList?.map((item) => (
                      <MenuItem
                        onClick={() => {
                          setSelectedTopic(item);
                          getQuestionList(item?.id);
                        }}
                        value={item?.id}
                      >
                        {item?.title}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </Box>
            </Box>

            <Box>
              <div id="eq-questions-map-hd">Questions List</div>
              <Box sx={{ mt: 2, pb: 20 }}>
                {loadingQuestion ? (
                  [1, 2, 3, 4].map(() => (
                    <Skeleton height={60} width={"100%"} sx={{ mb: 1 }} />
                  ))
                ) : !loadingQuestion && questionsList?.length == 0 ? (
                  <Box
                    sx={{
                      py: 20,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant="subtitle1" sx={{ color: "#ddd" }}>
                      No Questions Found
                    </Typography>
                  </Box>
                ) : (
                  questionsList?.map((item, index) => (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        columnGap: 2,
                        mb: 0.5,
                        overflowX: "scroll",
                        borderRadius: 2,
                        p: {xs: 1.5, lg: 2},
                        bgcolor: "#eee",
                        position: 'relative'
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 2,
                          height: "auto",
                          p: 1,
                          flex: 1
                        }}
                      >
                        <Typography variant={"h6"} fontWeight={800}>
                          {index < 10 ? `${index + 1}` : index}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            flex: 1,
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            maxWidth: {xs: '15rem', lg: '35rem'},
                            overflowX: "scroll",
                            overflowY: "hidden",
                          }}
                        >
                          {item?.title}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", columnGap: 1, position: 'absolute', right: 0, bgcolor: '#eee', py: 1, px: {xs: 1, lg: 2}}}>
                        <IconButton sx={{bgcolor: 'primary.main', color: '#fff', borderRadius: 2, '&:hover': {bgcolor: 'primary.dark'}}}
                          onClick={() => {
                            setSelectedQuestion(item);
                            showMap(false);
                            showEdit(true);
                          }}                        
                        >
                          <Edit />{" "}
                        </IconButton>
                        <IconButton sx={{bgcolor: 'error.main', color: '#fff', borderRadius: 2, '&:hover': {bgcolor: 'error.dark'}}}
                          onClick={() => {
                            setSelectedQuestion(item);
                            setOpenDeleteDialog(true);
                          }}                          
                        >
                          <Delete />{" "}
                        </IconButton>
                      </Box>
                    </Box>
                  ))
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {edit && (
        <EditQuestionPage
          data={selectedQuestion}
          showMap={showMap}
          showEdit={showEdit}
          refresh={() => {
            getQuestionList(selectedTopic?.id);
          }}
        />
      )}
      {
        <QuestionDelete
          open={openDeleteDialog}
          setOpen={setOpenDeleteDialog}
          question={selectedQuestion}
          refresh={() => {
            getQuestionList(selectedTopic?.id);
          }}
        />
      }
    </Box>
  );
};

export default EditQuestion;
