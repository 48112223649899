import { createSlice } from "@reduxjs/toolkit";

export const menuState = createSlice({
  name: "menu",
  initialState: {
    value: {
      menuSt: "0",
    },
  },
  reducers: {
    changeTo: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { changeTo } = menuState.actions;

export default menuState.reducer;
