import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import styled from "styled-components";
import data from "../../Config/data";
import api from "../../Config/api";
import { access_key } from "../../Config/util";
import axios from "axios";

const PreLoader = () => {
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {
    if (localStorage.getItem("isLogged") === "true") {
      checkData();
    } else {
      navigate("/login", { replace: true });
    }
  }, []);

  const checkData = async () => {
    const response = await axios.get(`${api.user.details}${userData?.id}`);
    if (response?.data?.teacherFound) {
      localStorage.setItem(
        "userData",
        JSON.stringify({
          id: response?.data?.id,
          user_name: response?.data?.username,
          profile: response?.data?.profile,
          email: response?.data?.email,
          phone_no: response?.data?.phone,
          role_id: response?.data?.role_id,
          stream_id: response?.data?.stream_id,
          subject_id: response?.data?.subject_id,
          teacher_id: response?.data?.teacher_id,
          name: response?.data?.name,
        }),
      );
      navigate("/home");
    } else {
      navigate("/login", { replace: true });
      localStorage.clear();
    }

    fetch(`${api.user.details}?${access_key}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        id: userData.id,
      }),
    })
      .then((res) => res.json())
      .then((val) => {
        if (val.status === "400") {
          navigate("../login", { replace: true });
          localStorage.clear("userData");
          localStorage.clear("isLogged");
        } else {
          navigate("../home", { replace: true });
          data.user_details = val;
          localStorage.setItem(
            "userData",
            JSON.stringify({
              id: val.id,
              user_name: val.username,
              profile: val.profile,
              email: val.email,
              phone_no: val.phone,
              role_id: val.role_id,
              stream_id: val.stream_id,
              subject_id: val.subject_id,
              teacher_id: val.teacher_id,
              name: val.name,
            }),
          );
        }
      });
  };

  return (
    <Cont>
      <Lottie
        loop
        autoPlay
        style={{ height: 100, width: 100 }}
        animationData={require("../../Assets/Animations/morphing-loading.json")}
      />
    </Cont>
  );
};

export default PreLoader;

const Cont = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;
