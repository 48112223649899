import React, { useEffect, useState } from "react";
import api from "../../../../Config/api";
import data from "../../../../Config/data";
import { access_key } from "../../../../Config/util";
import "./notification.scss";
import NotificationShimmer from "./Noti_Shimmer/noti_shimmer";
import toast, { Toaster } from "react-hot-toast";
import userPlaceHolder from "../../../../Images/Icons/userPlaceholder.png";
import { useSelector, useDispatch } from "react-redux";
import { setShowNoti } from "../../../../Features/notificationState";
import { setAllNoti } from "../../../../Features/notificationStateAll";
import { useNavigate } from "react-router-dom";
import imgClose from "../../../../Images/Icons/closeBtn.svg";
import axios from "axios";

const NotiSet = ({ l }) => {
  return (
    <div className="list-cont">
      <div className="image-cont">
        <img src={l.image === "null" ? userPlaceHolder : l.image} alt="photo" />
      </div>
      <div className="title-context-cont">
        <span className="title">{l.title}</span>
        <span className="content">{l.content}</span>
      </div>
    </div>
  );
};

const NotificationSmall = () => {
  const [notiList, setNotiList] = useState([]);
  const [isShimmer, setIsShimmer] = useState(true);
  const notiStss = useSelector((state) => state.noti.value);
  const [showAll, setShowAll] = useState(false);
  const nav = useNavigate();
  const dispatch = useDispatch();

  useEffect(async () => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const response = await axios.get(
      `${api.user.notifications}${userData?.id}&page=1&limit=20`,
    );
    if (response?.data?.isData) {
      setShowAll(true);
      setNotiList(response?.data?.notifications);
      setIsShimmer(false);
    } else {
      setIsShimmer(true);
      setNotiList([]);
      toast.dismiss();
      toast(
        (t) => (
          <span className="toast-cont">
            <div>No notifications avalable!</div>
          </span>
        ),
        { duration: 5000 },
      );
    }
  }, []);

  const closeWin = () => {
    dispatch(setShowNoti({ notiSt: false }));
  };

  const openAllNoti = () => {
    dispatch(setShowNoti({ notiSt: false }));
    nav("home/notification");
  };

  return (
    <div className="noti-sml-container">
      <div id="tt">
        Notification
        <img src={imgClose} onClick={closeWin} />
      </div>

      <div id="noti-main-cont">
        {isShimmer ? (
          <NotificationShimmer />
        ) : (
          notiList.map((i) => <NotiSet l={i} />)
        )}
      </div>

      {showAll ? (
        <div id="dwn" onClick={openAllNoti}>
          Show All
        </div>
      ) : (
        <div className="dwn"></div>
      )}
    </div>
  );
};

export default NotificationSmall;
