import {
  filledInputClasses,
  outlinedInputClasses,
  paperClasses,
} from "@mui/material";
import { palette } from "./palette";

export const components = {
  MuiButton: {
    defaultProps: {
      fullWidth: false,
    },
    styleOverrides: {
      root: {
        padding: "0.4rem 1rem",
        borderRadius: 8,
        fontWeight: 500,
        fontSize: 14,
        textTransform: "none",
        color: "#fff",
      },
      outlined: {
        color: "inherit",
        textTransform: "none",
        border: "1px solid #0284ae",
        "&:hover": {
          backgroundColor: "#f8f9fa",
          border: "1px solid #015c79",
        },
      },
      startIcon: {
        svg: {
          width: 18,
          height: 18,
        },
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: 15,
        // border: `1.5px solid #00000030`,
        [`&.${paperClasses.elevation1}`]: {
          boxShadow:
            "0px 5px 10px rgba(0, 0, 0, 0.04), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03)",
        },
        backgroundColor: "#fff",
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        borderRadius: 15,
        [`&.${paperClasses.elevation1}`]: {
          boxShadow:
            "0px 4px 20px rgba(0, 0, 0, 0.08), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03)",
        },
      },
    },
  },
  MuiOutlinedInput: {
    defaultProps: {
      size: "small",
    },
    styleOverrides: {
      root: {
        borderRadius: 6,
        border: "none",
        "&:hover": {
          border: "none",
          // [`& .${outlinedInputClasses.notchedOutline}`]: {
          //   borderColor: "red",
          // },
        },
        [`&.${outlinedInputClasses.focused}`]: {
          backgroundColor: "transparent",
          [`& .${outlinedInputClasses.notchedOutline}`]: {
            // borderColor: palette.primary.main,
            borderColor: "#000",
          },
        },
        [`&.${filledInputClasses.error}`]: {
          [`& .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: palette.error.main,
          },
        },
      },
      notchedOutline: {
        borderWidth: "1px",
        borderColor: "#ddd",
      },
    },
  },
  MuiFormControl: {
    defaultProps: {
      fullWidth: true,
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        fontSize: 14,
        lineHeight: 1,
        color: palette.text.primary,
        fontWeight: 300,
        marginTop: -2,
      },
    },
  },
  MuiMobileDatePicker: {
    styleOverrides: {
      root: {
        backgroundColor: "",
      },
    },
  },
  MuiTableContainer: {
    styleOverrides: {
      root: {
        borderRadius: 10,
        // border: `1.5px solid #00000010`,
        boxShadow:
          "0px 5px 22px rgba(0, 0, 0, 0.04), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03)",
      },
    },
  },
  MuiTable: {
    styleOverrides: {
      root: {
        borderRadius: 4,
      },
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: {
        borderTop: "1.5px solid #e0e0e0",
        borderBottom: "1.5px solid #e0e0e0",
        backgroundColor: "#f8f9fa",
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        border: "none",
      },
    },
  },
  MuiSkeleton: {
    defaultProps: {
      animation: "wave",
      variant: "rounded",
    },
    styleOverrides: {
      root: {
        borderRadius: 6,
      },
    },
  },
};
