import React from "react";
import styled from "styled-components";
import "./noti_shimmer.scss";
import { Shimmer } from "react-shimmer";

const NotificationShimmer = () => {
  return (
    <div className="shimmer-wrap">
      <div className="demo-cont">
        <div className="img-circle"></div>
        <div className="demo-line one"></div>
        <div className="demo-line two"></div>
      </div>

      <div className="demo-cont">
        <div className="img-circle"></div>
        <div className="demo-line one"></div>
        <div className="demo-line two"></div>
      </div>

      <div className="demo-cont">
        <div className="img-circle"></div>
        <div className="demo-line one"></div>
        <div className="demo-line two"></div>
      </div>

      <div className="demo-cont">
        <div className="img-circle"></div>
        <div className="demo-line one"></div>
        <div className="demo-line two"></div>
      </div>

      <div className="demo-cont">
        <div className="img-circle"></div>
        <div className="demo-line one"></div>
        <div className="demo-line two"></div>
      </div>
    </div>
  );
};

export default NotificationShimmer;
