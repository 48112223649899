import React, { useEffect } from "react";
import {
  HashRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import { Helmet } from "react-helmet";

import "./home.scss";
import Sidenav from "../Sidenav/sidenav";
import Topnav from "../Topnav/topnav";
import data from "../../../Config/data";

//screens
import Dashboard from "../Dashboard/index";
import CreateChapter from "../CreateChapter/create-chapter";
import UploadQuestion from "../UploadQuestion/uploadQuestion";
import UploadContent from "../UploadContent/uploadContent";
import EditQuestion from "../EditQuestion/edit-question";
import EditContent from "../EditContent/edit-content";
import Messages from "../Messages/messages";
import Report from "../Report/report";
import Support from "../Support/Support";
import EditAccount from "../EditAccout/edit_account";
import NotificationAll from "../NotificationAll/notification_all";

const Home = () => {
  const nav = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("isLogged") === "true") {
      const datas = localStorage.getItem("userData");
      data.user_details = JSON.parse(datas);
    } else {
      nav("../", { replace: true });
    }
  }, []);

  return (
    <div className="home-cont">
      <Helmet>
        <title>DreamCatchr | DashBoard</title>
        <meta name="description" content="Dashboard" />
      </Helmet>

      <Topnav />
      <Sidenav />

      <div className="display-scn-cont">
        <Routes>
          <Route path="home/" element={<Dashboard />} />
          <Route path="home/add-question" element={<UploadQuestion />} />
          <Route path="home/add-topic" element={<UploadContent />} />
          <Route path="home/edit-question" element={<EditQuestion />} />
          <Route path="home/edit-topic" element={<EditContent />} />
          <Route path="home/create-chapter" element={<CreateChapter />} />
          <Route path="home/report" element={<Report />} />
          <Route path="home/support" element={<Support />} />
          <Route path="home/messages" element={<Messages />} />
          <Route path="home/edit-account" element={<EditAccount />} />
          <Route path="home/notification" element={<NotificationAll />} />
        </Routes>
      </div>
    </div>
  );
};
export default Home;
