import React, { useState, useEffect } from "react";
import "./topnav.scss";
import HamMenu from "./Menu/menu";
import Account from "./Account/account";
import NotificationSmall from "./Notification/notification";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

//icons
import logo from "../../../Images/Icons/Black_long.svg";
import userLogo from "../../../Images/Icons/userTwosvg.svg";
import notifLogo from "../../../Images/Icons/bell.svg";
import chevDown from "../../../Images/Icons/chevron-down.svg";
import { setShowNoti } from "../../../Features/notificationState";
import api from "../../../Config/api";
import { access_key } from "../../../Config/util";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { APP_VERSION } from "../../../Config/config";

const Topnav = () => {
  const [accSt, setAccSt] = useState("closed");
  const notiSts = useSelector((state) => state.noti.value);
  const dispatch = useDispatch();
  const nav = useNavigate();

  const subId = Number(localStorage.getItem("subject_id"));
  const subDetails = JSON.parse(localStorage.getItem("subject_details"));

  const subjects = JSON.parse(localStorage.getItem("userData"));
  const subjectSplit = subjects.subject_id.split(",");
  const [subjectList, setSubjectList] = useState([]);

  const [dropSubs, setDropSubs] = useState(false);
  const [subNow, setSubNow] = useState(subDetails);

  const getSubName = async (id) => {
    const subArr = [];
    subjectSplit.map((i) => {
      (async function (k) {
        const response = await axios.get(`${api.subject.list}${k}`);
        if (response?.data?.subjectFound) {
          subArr.push(response?.data);
          setSubjectList(subArr);
        }
      })(i);
    });
  };

  const filterNow = () => {
    setSubjectList(subjectList.filter((j) => Number(j.id) != subId));
  };

  const getCurrentSubject = async () => {
    const response = await axios.get(`${api.subject.list}${subId}`);
    if (response?.data?.subjectFound) {
      setSubNow(response?.data);
    }
  };

  useEffect(() => {
    getSubName();
    getCurrentSubject();
  }, []);

  const chngAccSt = () => {
    if (accSt === "closed") {
      setAccSt("open");
      dispatch(setShowNoti({ notiSt: false }));
    } else {
      setAccSt("closed");
    }
  };

  const chngNotiSt = () => {
    if (notiSts.notiSt === false) {
      dispatch(setShowNoti({ notiSt: true }));
      setAccSt("closed");
    } else {
      dispatch(setShowNoti({ notiSt: false }));
    }
  };

  return (
    <div className="topnav-cont">
      <div className="topnav-left">
        <div className="menu-btn">
          <HamMenu />
        </div>
        <div className="logo-text">
          <img src={logo}></img>
        </div>

        <Subject
          lft={window.innerWidth}
          clk={dropSubs}
          id="topnav-subject-select"
          onClick={() => {
            setDropSubs(!dropSubs ? true : false);
            filterNow();
          }}
        >
          <span className="topnav-sub-sel" value={subId}>
            {subNow ? `${subNow.class} ${subNow.subject_name}` : "Loading"}
            <Drop rt={dropSubs} src={chevDown} />
          </span>
          {dropSubs && (
            <SubjectDrop
              wd={window.innerWidth}
              className="subsel-drop-conatiner"
            >
              <span className="subsel-hd">SUBJECTS</span>
              {subjectList &&
                subjectList.map((i) => (
                  <span
                    onClick={() => {
                      localStorage.setItem("subject_id", Number(i.id));
                      localStorage.setItem(
                        "subjectDetails",
                        JSON.stringify({
                          subject_name: i.subject_name,
                          class: i.class,
                        }),
                      );
                      nav("../", { replace: true });
                    }}
                    value={Number(i.id)}
                  >
                    {i.class} {i.subject_name}
                  </span>
                ))}
            </SubjectDrop>
          )}
        </Subject>
      </div>

      <div className="topnav-mid"></div>

      <div className="topnav-right">
        <div id="version">VERSION {APP_VERSION}</div>
        <div className="rightic" id="notif" onClick={chngNotiSt}>
          <img src={notifLogo} alt="notifications" />
        </div>

        <div className="rightic account" onClick={chngAccSt}>
          <img src={userLogo} alt="user profile" />
        </div>

        {accSt === "open" && <Account setAccSt={setAccSt} />}
        {notiSts.notiSt && <NotificationSmall />}
      </div>
    </div>
  );
};

export default Topnav;

const Subject = styled.div`
  left: ${(props) => (props.lft < 900 ? "4.5rem" : "18rem")};
  background-color: ${(props) => (props.clk ? "#2576ef" : "#eee")};
  color: ${(props) => (props.clk ? "white" : "black")};
  font-weight: ${(props) => (props.clk ? "normal" : "600")};
`;

const SubjectDrop = styled.div`
  width: ${(props) => (props.wd < 900 ? "150%" : "200%")};
`;

const Drop = styled.img`
  transform: ${(props) => (props.rt ? "rotate(180deg)" : "rotate(0deg)")};
  filter: ${(props) => (props.rt ? "invert(100%)" : "none")};
`;
