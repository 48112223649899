import React, { useEffect, useState } from "react";
import "./edit-content.scss";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import api from "../../../Config/api";
import toast, { Toaster } from "react-hot-toast";
import EditContentPage from "./EditContentPage/edit-content-page";
import axios from "axios";
import {
  Box,
  Button,
  Dialog,
  MenuItem,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { TopicDelete } from "./topic-delete";

const EditContent = () => {
  const subId = Number(localStorage.getItem("subject_id"));

  //news
  const [map, showMap] = useState(true);
  const [edit, showEdit] = useState(false);

  // updates
  const [chapterList, setChapterList] = useState([]);
  const [topicList, setTopicList] = useState([]);
  const [loadingChapter, setLoadingChapter] = useState(false);
  const [loadingTopics, setLoadingTopics] = useState(false);
  const [selectedChapter, setSelectedChapter] = useState({});
  const [selectedTopic, setSelectedTopic] = useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  //chapter details calling
  const getChaptersList = async () => {
    setLoadingChapter(true);
    const response = await axios.get(
      `${api.chapter.list}${subId}?page=1&limit=100`,
    );
    if (response?.data?.chaptersFound) {
      setChapterList(response?.data?.chapters);
      setLoadingChapter(false);
    } else {
      setChapterList([]);
      toast.dismiss();
      toast(
        (t) => (
          <span className="toast-cont">
            <div>Please add new chapter to this subject</div>
            <div className="toastBtn">
              <button onClick={() => toast.dismiss(t.id)}>Close</button>
            </div>
          </span>
        ),
        { duration: 5000 },
      );
      setLoadingChapter(false);
    }
  };

  const getTopicList = async (idd) => {
    setLoadingTopics(true);
    const response = await axios.get(
      `${api.topic.list}${idd}?page=1&limit=100`,
    );
    if (response?.data?.message == "Topics found") {
      setTopicList(response?.data?.topics);
      setLoadingTopics(false);
    } else {
      setTopicList([]);
      toast.dismiss();
      toast(
        (t) => (
          <span className="toast-cont">
            <div>
              Failed to fetch contents, please add new content in this topic
            </div>
            <div className="toastBtn">
              <button onClick={() => toast.dismiss(t.id)}>Close</button>
            </div>
          </span>
        ),
        { duration: 5000 },
      );
      setLoadingTopics(false);
    }
  };

  useEffect(() => {
    if (chapterList?.length == 0) {
      getChaptersList();
    }
  }, []);

  return (
    <div id="ec-cont">
      <Helmet>
        <title>DreamCatchr | Edit Topic</title>
        <meta name="description" content="Edit Topic" />
      </Helmet>
      <div>
        <Toaster position="top-center" reverseOrder={false} />
      </div>
      {map && (
        <div id="ec-left">
          <div id="uq-hd">Edit Topic</div>
          <div id="ec-body">
            <div id="q-chap-top-sel">
              <Box sx={{ width: { xs: "100%", lg: "50%" } }}>
                <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                  Select Chapter
                </Typography>
                {loadingChapter ? (
                  <Skeleton height={40} width={"100%"} />
                ) : (
                  <TextField
                    select
                    fullWidth
                    variant="outlined"
                    value={selectedChapter?.id}
                  >
                    {chapterList?.map((item) => (
                      <MenuItem
                        onClick={() => {
                          getTopicList(item?.id);
                          setSelectedChapter(item);
                        }}
                        value={item?.id}
                      >
                        {item?.chapter_name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </Box>
            </div>

            <div id="ec-contents-map-hd">Topic List</div>
            <Box sx={{ mt: 2 }}>
              {loadingTopics ? (
                [1, 2, 3, 4].map(() => (
                  <Skeleton height={60} width={"100%"} sx={{ mb: 1 }} />
                ))
              ) : !loadingTopics && topicList?.length == 0 ? (
                <Box
                  sx={{
                    py: 20,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="subtitle1" sx={{ color: "#ddd" }}>
                    No Topic Found
                  </Typography>
                </Box>
              ) : (
                topicList?.map((item, index) => (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      columnGap: 2,
                      mb: 0.5,
                      overflowX: "scroll",
                      borderRadius: 2,
                      p: 2,
                      bgcolor: "#f7f7f7",
                      "&:hover": { bgcolor: "#eee" },
                    }}
                  >
                    <Box sx={{ display: "flex", columnGap: 3 }}>
                      <Typography variant="subtitle1" fontWeight={800}>
                        {index < 10 ? `${"0" + index}` : index}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          flex: 1,
                          whiteSpace: "nowrap",
                          minWidth: "15rem",
                        }}
                      >
                        {item?.title}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", columnGap: 1 }}>
                      <Button
                        onClick={() => {
                          setSelectedTopic(item);
                          showMap(false);
                          showEdit(true);
                        }}
                        variant="contained"
                        startIcon={<Edit />}
                      >
                        Edit
                      </Button>
                      <Button
                        onClick={() => {
                          setSelectedTopic(item);
                          setOpenDeleteDialog(true);
                        }}
                        variant="contained"
                        startIcon={<Delete />}
                        color="error"
                      >
                        Delete
                      </Button>
                    </Box>
                  </Box>
                ))
              )}
            </Box>
          </div>
        </div>
      )}
      {edit && (
        <EditContentPage
          data={selectedTopic}
          showMap={showMap}
          edit={edit}
          showEdit={showEdit}
          refresh={() => {
            getTopicList(selectedChapter?.id);
          }}
        />
      )}
      {
        <TopicDelete
          open={openDeleteDialog}
          setOpen={setOpenDeleteDialog}
          topic={selectedTopic}
          refresh={() => {
            getTopicList(selectedChapter?.id);
          }}
        />
      }
    </div>
  );
};

export default EditContent;
