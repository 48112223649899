import { createSlice } from "@reduxjs/toolkit";

export const notificationState = createSlice({
  name: "noti",
  initialState: { value: { notiSt: false } },
  reducers: {
    setShowNoti: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setShowNoti } = notificationState.actions;

export default notificationState.reducer;
