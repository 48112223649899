import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import "./popup-uq.scss";
import MathQuillAnswerEdit from "../MathQuill/answer-edit";

const PopUpUQ = ({ setPopUp, updateAnswer, defaultValue, onClose }) => {
  const [vals, setVals] = useState(defaultValue);
  const [disableEdit, setDisableSet] = useState(false);

  const onEdit = () => {
    setDisableSet(true);
    if (vals && vals.length > 2) {
      toast.loading("updating.", {
        duration: 1000,
      });
      var time = setTimeout(() => {
        setDisableSet(false);
        updateAnswer(vals);
        setPopUp(false);
      }, 1000);
    } else {
      setDisableSet(false);
      toast.error("Input a valid answer");
    }
    return () => {
      setDisableSet(false);
      clearTimeout(time);
    };
  };

  return (
    <div id="popup-cont">
      <Toaster position="top-center" reverseOrder={true} />
      <div id="pop-box-cont">
        <div>
          <label>Edit your answer</label>
          {/* <input type="text" placeholder="Enter your answer here" value={vals} onChange={e => {setVals(e.target.value)}}/> */}
          <div className="pop-box-edit-ans">
            <MathQuillAnswerEdit value={vals} setValue={setVals} />
          </div>
          <button
            id="pop-submit"
            onClick={onEdit}
            disabled={disableEdit ? true : false}
          >
            Edit
          </button>
          <button
            id="pop-close-btn"
            onClick={() => {
              setPopUp(false);
              onClose();
            }}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopUpUQ;
