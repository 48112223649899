import React, { useState, useEffect } from "react";
import "./notification_all.scss";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { setAllNoti } from "../../../Features/notificationStateAll";
import { useDispatch } from "react-redux";
import api from "../../../Config/api";
import data from "../../../Config/data";
import { access_key } from "../../../Config/util";

import imgClose from "../../../Images/Icons/closeBtn.svg";
import imgPlaceH from "../../../Images/Icons/userPlaceholder.png";
import NotificationAllShimmer from "./Noti_all_shimmer/noti_all_shimmer";
import axios from "axios";

const NotiSet = ({ l }) => {
  return (
    <div className="no-solo-cont">
      <div className="no-std-img-cont">
        <img src={imgPlaceH} alt="" />
      </div>

      <div className="no-title-content-cont">
        <h4 className="no-not-title">{l.title}</h4>
        <p className="no-not-content">{l.content}</p>
      </div>
    </div>
  );
};

const NotificationAll = () => {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const [notiList, setNotiList] = useState([]);
  const [isShimmer, setIsShimmer] = useState(true);

  useEffect(async () => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const response = await axios.get(
      `${api.user.notifications}${userData?.id}&page=1&limit=20`,
    );
    if (response?.data?.isData) {
      setNotiList(response?.data?.notifications);
    }
    setIsShimmer(false);
  }, []);

  const closeBtn = () => {
    dispatch(setAllNoti({ allNoti: false }));
    nav("../home/", { replace: true });
  };

  return (
    <div className="notification-cont">
      <Helmet>
        <title>DreamCatchr | Notification</title>
        <meta name="description" content="Notification" />
      </Helmet>
      <img id="no-close-btn" src={imgClose} onClick={closeBtn} />
      <div className="not-cont-left">
        <div className="no-heading-hd">Notifications</div>
        <div className="no-all-noti-cont">
          {isShimmer ? (
            <NotificationAllShimmer />
          ) : (
            notiList.map((i) => <NotiSet l={i} />)
          )}
        </div>
      </div>
    </div>
  );
};

export default NotificationAll;
