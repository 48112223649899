import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import api from "../../../../Config/api";
import { access_key } from "../../../../Config/util";
import "../../../../Components/PopupUQ/popup-uq.scss";
import axios from "axios";

const PopUpCCDel = ({ setShowDelPopUp, chapterDetailsFun, chapterId }) => {
  const [deletes, setDeletes] = useState(false);

  const deleteChapter = async () => {
    setDeletes(true);
    const response = await axios.delete(`${api.chapter.delete}${chapterId}`);
    if (response?.data?.deleted) {
      chapterDetailsFun();
      setShowDelPopUp(false);
      toast.dismiss();
      toast.success("Chapter deleted successfully");
    } else {
      setDeletes(false);
      toast.dismiss();
      toast.error("Failed to delete data. Please try again");
    }
  };

  return (
    <div id="create-chapter-popup-page">
      <Toaster position="top-center" reverseOrder={true} />
      <div id="pop-del-box-cont">
        <div id="pop-del-box">
          <div className="pop-del-hd">Are you sure?</div>
          <div className="pop-del-hd">
            <button
              id="pop-del-submit"
              onClick={deleteChapter}
              disabled={deletes ? true : false}
            >
              Confirm
            </button>
            <button
              id="pop-del-close-btn"
              onClick={() => {
                setShowDelPopUp(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopUpCCDel;
