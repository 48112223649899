import React, { useState } from "react";
import "./edit-content-page.scss";
import api from "../../../../Config/api";
import toast from "react-hot-toast";
import RTEEdit from "../../../../Components/SunEditor/suneditor_edit";
import axios from "axios";
import { Box, Button, MenuItem, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const EditContentPage = ({ data, showMap, showEdit, refresh }) => {
  const [title, setTitle] = useState(data?.title);
  const [tag, setTag] = useState(data?.tag);
  const [content, setContent] = useState(data?.content);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    if (title && title.length > 4) {
      if (content && content.length >= 2) {
        const response = await axios.patch(api.topic.update, {
          topicId: data?.id,
          title: title,
          tag: tag,
          content: content,
        });
        if (response?.data?.updated) {
          refresh();
          showMap(true);
          showEdit(false);
          setLoading(true);
          toast.success("Topic uploaded successfully");
        } else {
          setLoading(false);
          toast.dismiss();
          toast.error("Failed to upload data. Please try again");
        }
      } else {
        setLoading(false);
        toast.dismiss();
        toast.error("Enter topic description");
      }
    } else {
      setLoading(false);
      toast.dismiss();
      toast.error("Enter a valid title");
    }
  };

  return (
    <div id="edit-Content-Page_Container">
      <div className="ec-body">
        <div className="ec-hd">Edit Topic</div>

        <Box>
          <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
            Title
          </Typography>
          <TextField
            value={title}
            type="text"
            placeholder="Enter Title"
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            fullWidth
            variant="outlined"
          />
        </Box>

        <Box sx={{ width: { xs: "100%", lg: "50%" }, mt: 1.5 }}>
          <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
            Select Tag
          </Typography>
          <TextField select value={tag} fullWidth variant="outlined">
            {[
              "Diagram",
              "Explanation",
              "Problem",
              "Experiment",
              "Short Note",
              "Points To Remember",
              "Interesting Facts",
            ].map((item) => (
              <MenuItem onClick={() => setTag(item)} value={item}>
                {item}
              </MenuItem>
            ))}
          </TextField>
        </Box>

        <Box mt={1.5}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Content
          </Typography>
          <Box
            id="rte-cont"
            sx={{
              border: "1px solid #aaa",
              borderRadius: 2,
              overflow: "hidden",
            }}
          >
            <RTEEdit value={content} setValue={setContent} />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            columnGap: 1,
            mt: 2,
            mb: 10,
          }}
        >
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              showMap(true);
              showEdit(false);
            }}
          >
            Discard
          </Button>
          <LoadingButton
            loading={loading}
            onClick={handleSubmit}
            variant="contained"
          >
            Submit
          </LoadingButton>
        </Box>
      </div>
    </div>
  );
};

export default EditContentPage;
