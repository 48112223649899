import { createSlice } from "@reduxjs/toolkit";

export const subjectState = createSlice({
  name: "subject",
  initialState: {
    value: {
      subSt: 1,
    },
  },
  reducers: {
    currentSub: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { currentSub } = subjectState.actions;

export default subjectState.reducer;
