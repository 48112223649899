import React from "react";
import ReactDOM from "react-dom";
import "./App.css";
import "./index.css";
import App from "./App";

//redux stuff
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import menuReducer from "./Features/menuState";
import pageReducer from "./Features/pageState";
import notiReducer from "./Features/notificationState";
import notiAllReducer from "./Features/notificationStateAll";
import subjectReducer from "./Features/subjectState";

const store = configureStore({
  reducer: {
    menu: menuReducer,
    page: pageReducer,
    noti: notiReducer,
    notiAll: notiAllReducer,
    sub: subjectReducer,
  },
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root"),
);
