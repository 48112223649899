import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./edit_account.scss";
import { setPageTo } from "../../../Features/pageState";
import imgPlaceH from "../../../Images/Icons/userPlaceholder.png";
import imgEdit from "../../../Images/Icons/editPen3.svg";
import imgClose from "../../../Images/Icons/closeBtn.svg";

import toast, { Toaster } from "react-hot-toast";
import api from "../../../Config/api";
import { access_key } from "../../../Config/util";
import data from "../../../Config/data";
import { validEmail } from "../../../Functions/utils";
import { useFilePicker } from "use-file-picker";
import axios from "axios";

const EditAccount = () => {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const data = JSON.parse(localStorage.getItem("userData"));

  const [image, setImage] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [nameEdit, setNameEdit] = useState(data.name);
  const [emailEdit, setEmailEdit] = useState(data.email);
  const [phoneEdit, setPhoneEdit] = useState(data.phone_no);
  const [currentPass, setCurrentPass] = useState("*******");
  const [newPass, setNewPass] = useState("");
  const [newPassConfirm, setNewPassConfirm] = useState("");
  const [isWait, setIsWait] = useState(false);
  const [isWaitPass, setIsWaitPass] = useState(false);

  const closeBtn = () => {
    dispatch(setPageTo({ pageSt: "scrnOne" }));
    nav("../home/", { replace: true });
  };

  const saveData = async () => {
    // imageUpload()
    if (nameEdit && nameEdit.length >= 4) {
      if (validEmail(emailEdit)) {
        if (phoneEdit && phoneEdit.length === 10) {
          setIsWait(true);
          const response = await axios.post(
            `${api.user.update}${data?.id}`,
            {
              name: nameEdit,
              email: emailEdit,
              phone: phoneEdit,
            },
          );
          if (response?.data?.updated) {
            setIsWait(false);
            toast.loading("Is updating..", { duration: 2000 });
            setTimeout(() => {
              nav("../", { replace: true });
            }, 1000);
          } else {
            toast.dismiss();
            toast.error("Error occoured!! Please wait");
          }
        } else {
          toast.dismiss();
          toast.error("Enter a valid phone number");
        }
      } else {
        toast.dismiss();
        toast.error("Enter a valid email");
      }
    } else {
      toast.dismiss();
      toast.error("Enter a valid name");
    }
  };

  const savePass = () => {
    if (newPass && newPass.length >= 8) {
      if (newPass != currentPass) {
        if (newPass === newPassConfirm) {
          setIsWaitPass(true);
          setTimeout(() => {
            toast.success("Password change successfull");
          }, 1500);
          setTimeout(() => {
            setIsWaitPass(false);
          }, 1500);
        } else {
          toast.dismiss();
          toast.error("Passwords not match");
        }
      } else {
        toast.dismiss();
        toast.error("New password cannot be old password");
      }
    } else {
      toast.dismiss();
      toast.error("Enter a valid password");
    }
  };

  const showMe = (e) => {
    //   // console.log(e.target.files)
    //   setImage(e.target.files[0])
    //   // let reader = new FileReader();
    //   // reader.readAsDataURL(e.target.files[0])
    //   // reader.onload = (d) => {
    //     // console.log(d.target.result)
    //     // setImage(d.target.result)
    //     imageUpload(e.target.value)
    //   // }
  };

  const inputRef = useRef();

  const selectFile = (e) => {
    setImage(inputRef.current.files[0]);
    // const j = JSON.stringify(e.target.value)
    // toast.success(j)
    // imageUpload(e)
  };

  const imageUpload = async (e) => {
    var photo = {
      uri: e.target.input.files[0],
      type: "image/jpeg",
      name: "testing",
    };

    var formData = new FormData();
    formData.append("image_file", photo);
    fetch(`${api.upload_profile}?${access_key}`, {
      method: "POST",
      body: formData,
      headers: {
        "Content-Type": "multipart/form-data;",
      },
    })
      .then((response) => response.text())
      .then((res) => {
        // toast.error(JSON.stringify(res))
      });
  };

  return (
    <div className="acc-edit-account-cont">
      <Helmet>
        <title>DreamCatchr | Account</title>
        <meta name="description" content="Account" />
      </Helmet>
      <div>
        <Toaster position="top-center" reverseOrder={false} />
      </div>
      <img id="acc-close-btn" src={imgClose} onClick={closeBtn} />
      <div className="acc-hero-cont">
        <div className="acc-edit-hd">Edit Profile</div>

        <div className="acc-img-cont">
          <div id="acc-img-flex">
            <img className="acc-user-img" src={imgPlaceH} alt="photo" />
            {/* <img className="acc-edit-img" src={imgEdit} alt="pen"/> */}
            {/* <input type="file" onChange={showMe}/> */}
          </div>
          {/* <input type="file" onChange={selectFile} ref={inputRef}/>
          <img src={image} />
          <button onClick={imageUpload}>Upload</button> */}
        </div>

        <div className="acc-form-cont">
          <div className="acc-cent-form">
            <div className="acc-form-chng-div">
              <span className="acc-demo-txts">Name : </span>
              <br />
              <input
                type="text"
                value={nameEdit}
                onChange={(e) => {
                  setNameEdit(e.target.value);
                }}
              />
            </div>

            <div className="acc-form-chng-div">
              <span className="acc-demo-txts">Email : </span>
              <br />
              <input
                type="text"
                value={emailEdit}
                onChange={(e) => {
                  setEmailEdit(e.target.value);
                }}
              />
            </div>

            <div className="acc-form-chng-div">
              <span className="acc-demo-txts">Phone No : </span>
              <br />
              <input
                type="text"
                value={phoneEdit}
                onChange={(e) => {
                  setPhoneEdit(e.target.value);
                }}
              />
            </div>
          </div>
        </div>

        <div className="acc-save-btn">
          <button onClick={closeBtn}>Discard Changes</button>
          {isWait ? (
            <button id="pls-btn">
              <div className="ge"></div>Please wait...
            </button>
          ) : (
            <button onClick={saveData}>Save</button>
          )}
        </div>

        {/* <div className="acc-form-chng-pass">
          <h4>Change Password</h4>
          
          <div className="acc-cent-form">
          <div className="acc-form-chng-div">
            <span className="acc-demo-txts">Current Password : </span><br />
            <input type="text" value={currentPass} onChange={e => {setCurrentPass(e.target.value)}}/>
          </div>

          <div className="acc-form-chng-div">
            <span className="acc-demo-txts">New Password : </span><br />
            <input type="text" value={newPass} onChange={e => {setNewPass(e.target.value)}}/>
          </div>
          
          <div className="acc-form-chng-div">
            <span className="acc-demo-txts">Confirm New Password : </span><br />
            <input type="text" value={newPassConfirm} onChange={e => {setNewPassConfirm(e.target.value)}}/>
          </div>
          </div>
        </div>

        <div className="acc-save-btn-two">
          <button onClick={closeBtn}>Discard Changes</button>
          {
            isWaitPass ? <button id="pls-btn"><div className="ge"></div>Please wait...</button> : <button onClick={savePass}>Save</button>
          }
        </div> */}
      </div>
    </div>
  );
};

export default EditAccount;
