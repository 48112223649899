import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./pass_reset.scss";
import styled from "styled-components";

const PassReset = () => {
  const nav = useNavigate();

  return (
    <div className="pass-reset-cont">
      <div className="inner-cont">
        <div className="title-hd">
          <h2>Password Reset</h2>
          <p>
            Enter your email and we'll send you a link to get back into your
            account.
          </p>
        </div>

        <div className="form">
          <input id="email-inp" type="email" placeholder="Enter your email" />
          <button>Send Login Link</button>
        </div>

        <button
          className="back-btn"
          onClick={() => {
            nav("../login", { replace: true });
          }}
        >
          Back To Login
        </button>
      </div>
    </div>
  );
};

export default PassReset;
