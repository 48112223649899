import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "./Style/dashboard.scss";
import "./Style/recent_chats.scss";
import data from "../../../Config/data";
import api from "../../../Config/api";
import { access_key } from "../../../Config/util";
import messLogo from "../../../Images/Icons/message4.svg";
import userPlaceHolder from "../../../Images/Icons/userPlaceholder.png";
import RecentChatsShimmer from "./Dashboard_Shimmer/recent-chats-shimmer";

const Dashboard = () => {
  const [chatList, setChatList] = useState([]);
  const [isPhone, setIsPhone] = useState(false);
  const [dropDown, setDropDown] = useState(null);
  const [isShimmer, setIsShimmer] = useState(true);
  const [noChats, setNoChats] = useState(false);
  const fake_chats = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  // const selectChat = () => {setCurrentPage('scrnEight')}

  const getChats = async () => {
    data.user_details = JSON.parse(localStorage.getItem("userData"));
    const last_chat = await fetch(
      `${api.teacher_chat_last_five}${data.user_details.id}${access_key}`,
    );
    const res = await last_chat.json();
    if (res.status === "400") {
      setIsShimmer(true);
      setNoChats(true);
    } else {
      setChatList(res);
      setIsShimmer(false);
    }
  };

  useEffect(async () => {
    getChats();
  }, []);

  useEffect(() => {
    window.innerWidth < 450 ? setIsPhone(true) : setIsPhone(false);
  }, [isPhone]);

  const ChatRowDesktop = ({ l }) => {
    return (
      <div className="data-table">
        <span>{l.chat_id}</span>
        <span>{l.title}</span>
        <span>
          <img
            src={l.image === "null" ? userPlaceHolder : l.image}
            alt="photo"
          />
          Student Name
        </span>
        <span>{l.class}</span>
        <span
          className={
            l.urgency === "1" ? "high-p" : l.urgency === "2" ? "mid-p" : "low-p"
          }
        >
          {l.urgency === "1" ? "HIGH" : l.urgency === "2" ? "MEDIUM" : "LOW"}
        </span>
        <span>{l.created_date}</span>
        <span>{l.status}</span>
        <span>
          <img src={messLogo} alt="" />
        </span>
      </div>
    );
  };

  const PreLoad = () => {
    return (
      <div className="preload-cont">
        <div id="preload">
          {/* <div id="rt"></div> */}
          Oops! chats are empty
        </div>
      </div>
    );
  };

  return (
    <Cont className="dashCont">
      {/* <div className="top-three-cont">
        <div className="top-three-div" ></div>
        <div className="top-three-div" ></div>
        <div className="top-three-div" ></div>
      </div> */}

      <div className="second-cont">
        <div className="second-div">
          <h4>Recent chats</h4>
          <div className="scroll">
            <div className="head-table-cont">
              <div>TASK ID</div>
              <div>SUMMARY</div>
              <div>ASSIGNEE</div>
              <div>CLASS</div>
              <div id="urgency-div">URGENCY</div>
              <div>CREATED DATE</div>
              <div>STATUS</div>
              <div>ACTION</div>
            </div>
            <div className="data-table-cont">
              {!isShimmer &&
                chatList &&
                chatList.map((i) => <ChatRowDesktop l={i} />)}
              {isShimmer &&
                !noChats &&
                fake_chats.map((i) => <RecentChatsShimmer />)}
              {isShimmer && noChats && <PreLoad />}
            </div>
          </div>
        </div>
      </div>
    </Cont>
  );
};

export default Dashboard;

const Cont = styled.div``;
