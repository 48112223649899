import React, { useState, useEffect } from "react";
import "./report.scss";

import api from "../../../Config/api";
import { access_key } from "../../../Config/util";
import { Helmet } from "react-helmet";
import toast, { Toaster } from "react-hot-toast";
import PopUpReport from "./PopupReport/popup-report";
import axios from "axios";

const Report = () => {
  const [title, setTitle] = useState();
  const [message, setMessage] = useState();
  const [priority, setPriority] = useState();
  const [submiting, setSubmitting] = useState(false);
  const [popup, setPopup] = useState(false);
  const [successMessage, setSuccessMessage] = useState();

  const userDetails = JSON.parse(localStorage.getItem("userData"));

  const sendReport = async () => {
    setSubmitting(true);
    if (title && title.length > 4) {
      if (message && message.length > 10) {
        if (priority != null && priority != "Select priority") {
          const response = await axios.post(api.report, {
            facultyId: userDetails.id,
            title: title,
            subtitle: message,
            priority,
          });
          if (response?.data?.issueCreated) {
            setTitle("");
            setMessage("");
            setPriority("Select priority");
            setSubmitting(false);
            setSuccessMessage(response?.data);
            setPopup(true);
          } else {
            toast.dismiss();
            setSubmitting(false);
            toast.error("Failed to send data! please try again later");
          }
        } else {
          toast.dismiss();
          setSubmitting(false);
          toast.error("Please select the priority of the issue");
        }
      } else {
        toast.dismiss();
        setSubmitting(false);
        toast.error("Please describe the issue");
      }
    } else {
      toast.dismiss();
      setSubmitting(false);
      toast.error("Please enter the nature of the issue");
    }
  };

  return (
    <div id="report-body-container">
      <Helmet>
        <title>DreamCatchr | Report</title>
        <meta name="description" content="Report" />
      </Helmet>
      <div>
        <Toaster position="top-center" reverseOrder={false} />
      </div>
      <div id="report-head-text-cont">
        If you are facing any issue, please let us know
      </div>
      <div id="report-hero-cont">
        <div className="report-issue-input-title">
          <h4>Nature of the issue</h4>
          <br />
          <input
            type="text"
            value={title}
            placeholder="I have an issue on..."
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />
        </div>
        <div className="report-issue-input-title">
          <h4>Message</h4>
          <br />
          <textarea
            value={message}
            placeholder="Describe the issue"
            onChange={(e) => {
              setMessage(e.target.value);
            }}
          ></textarea>
        </div>
      </div>

      <div id="report-issue-submit">
        <div id="set-priority-cont">
          <select
            value={priority}
            onChange={(e) => {
              setPriority(e.target.value);
            }}
          >
            <option disabled={priority != null ? true : false}>
              Select priority
            </option>
            <option>Low</option>
            <option>Medium</option>
            <option>High</option>
            <option>Risk</option>
          </select>
        </div>

        <button onClick={sendReport}>Report</button>
        {popup && <PopUpReport dat={successMessage} showPopup={setPopup} />}
      </div>
    </div>
  );
};

export default Report;

// cols="70" rows="8"
