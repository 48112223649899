import React, { useState, useEffect } from "react";
import "./support.scss";
import styled from "styled-components";
import chevDown from "../../../Images/Icons/chevron-down.svg";
import { Helmet } from "react-helmet";
import toast, { Toaster } from "react-hot-toast";
import api from "../../../Config/api";
import { access_key } from "../../../Config/util";
import axios from "axios";
import { Box } from "@mui/material";

const Support = () => {
  const [dropdown, setDropdown] = useState(1000);
  const [data, setData] = useState();
  const [id, setId] = useState();

  const getFAQ = async () => {
    const response = await axios.get(api.faq);
    if (response?.data?.faqsFound) {
      setData(response?.data?.faqs);
    } else {
      toast.dismiss();
      toast(
        (t) => (
          <span className="toast-cont">
            <div>
              Failed to fetch contents, please add new content in this topic
            </div>
            <div className="toastBtn">
              <button onClick={() => toast.dismiss(t.id)}>Close</button>
            </div>
          </span>
        ),
        { duration: 5000 },
      );
      setData([]);
    }
  };
  useEffect(() => {
    getFAQ();
  }, []);

  const SupportBox = ({ data }) => {
    return (
      <div
        className="support-box-cont"
        onClick={() => {
          dropdown === data?.id ? setDropdown(1000) : setDropdown(data?.id);
        }}
      >
        <div className="support-box-title">{data?.title}</div>
        <div className="support-drop-button">
          <img
            className={
              dropdown === data?.id
                ? "support-drop-img rotate-down"
                : "support-drop-img rotate-up"
            }
            src={chevDown}
            onClick={() => {
              dropdown === data?.id ? setDropdown(1000) : setDropdown(data?.id);
            }}
          />
        </div>
        {dropdown === data?.id && (
          <div anime={dropdown} className="support-content">
            {data?.subtitle}
          </div>
        )}
      </div>
    );
  };

  //all boxes dropping down on click
  return (
    <Box>
      SUPPORTS COMING SOON
      {/* <SupportBox data={data?.map(j => j)}/> */}
    </Box>
  );
};

export default Support;
