import React, { useState, useEffect } from "react";
import "./uploadQuestion.scss";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import "../../../../node_modules/suneditor/dist/css/suneditor.min.css";
import toast, { Toaster } from "react-hot-toast";
import PopUpUQ from "../../../Components/PopupUQ/popup-uq";
import api from "../../../Config/api";
import { access_key } from "../../../Config/util";

import RTEUpload from "../../../Components/SunEditor/suneditor_upload";

import MathQuillAnswerEdit from "../../../Components/MathQuill/answer-edit";
import MathQuillQuestionEdit from "../../../Components/MathQuill/question-edit";
import MathQuillAnswerShow from "../../../Components/MathQuill/answer-show";
import axios from "axios";
import {
  Box,
  Button,
  MenuItem,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

const UploadQuestion = () => {
  const [phone, setPhone] = useState(window.innerWidth < 1100 ? true : false);
  const [tempAnsId, setTempAnsId] = useState(0);

  const [loadingChapter, setLoadingChapter] = useState(false);
  const [chaptersList, setChaptersList] = useState([]);
  const [selectedChapter, setSelectedChapter] = useState(null);
  const [loadingTopic, setLoadingTopic] = useState(false);
  const [topicsList, setTopicsList] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [question, setQuestion] = useState("");
  const [questionDescrpition, setQuestionDescription] = useState("");
  const [difficultyLevel, setDifficultyLevel] = useState("Easy");

  const [submitting, setSubmitting] = useState(false);

  const [tempAnsKey, setTempAnsKey] = useState("");
  const [tempAnsSt, setTempAnsSt] = useState("false");
  const [correctAnsSel, setCorrectAnsSel] = useState(false);
  const [ansSet, setAnsSet] = useState([]);
  const [tempAnsDes, setTempAnsDes] = useState("");
  const [popUp, setPopUp] = useState(false);
  const [tempEditId, setTempEditId] = useState();
  const [submit, setSubmit] = useState(false);
  const [clearRTE, setClearRTE] = useState(false);

  const userDetails = JSON.parse(localStorage.getItem("userData"));
  const subId = Number(localStorage.getItem("subject_id"));

  const addToAnsList = () => {
    if (tempAnsKey === "") {
      toast.dismiss();
      toast.error("Enter some value");
    } else {
      setAnsSet([
        ...ansSet,
        {
          // id: tempAnsId,
          // key: tempAnsId,
          value: tempAnsKey,
          isAnswer: tempAnsSt,
        },
      ]);
      for (let i = 0; i < ansSet.length; i++) {
        ansSet[i].id = i;
        ansSet[i].key = i;
      }
      console.log(ansSet);
      // setTempAnsId(tempAnsId + 1);
      setTempAnsKey("");
    }
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      if (selectedChapter?.id > 0) {
        if (selectedTopic?.id > 0) {
          if (question && question.length > 5) {
            if (ansSet && ansSet.length >= 2) {
              if (correctAnsSel) {
                const response = await axios.post(api.question.create, {
                  subjectId: subId, //
                  chapterId: selectedChapter?.id, ///
                  topicId: selectedTopic?.id, //
                  title: question,
                  subtitle: questionDescrpition,
                  solution: tempAnsDes,
                  level: difficultyLevel,
                  options: JSON.stringify(ansSet),
                  facultyId: userDetails.id, //id
                });
                if (response?.data?.questionCreated) {
                  setTempAnsId(0);
                  setQuestion("");
                  setQuestionDescription("");
                  setDifficultyLevel("");
                  setTempAnsKey("");
                  setTempAnsSt("false");
                  setTempAnsDes("");
                  setCorrectAnsSel(false);
                  setAnsSet([]);
                  setClearRTE(true);
                  setSubmitting(false);
                  toast.dismiss();
                  toast.success("Question Added");
                } else {
                  setSubmitting(false);
                }
              } else {
                setSubmitting(false);
                toast.dismiss();
                toast.error("Enter a atleast one correct answer");
              }
            } else {
              setSubmitting(false);
              toast.dismiss();
              toast.error("Enter a valid answer or atleast two answer keys");
            }
          } else {
            setSubmitting(false);
            toast.dismiss();
            toast.error("Enter a valid title");
          }
        } else {
          setSubmitting(false);
          toast.dismiss();
          toast.error("Select a topic");
        }
      } else {
        setSubmitting(false);
        toast.dismiss();
        toast.error("Select a chapter");
      }
    } catch (error) {
      setSubmitting(false);
      toast.dismiss();
      toast.error("Network Error");
    }
  };

  const getChaptersList = async () => {
    setLoadingChapter(true);
    const response = await axios.get(
      `${api.chapter.list}${subId}?page=1&limit=100`,
    );
    if (response?.data?.chaptersFound) {
      setLoadingChapter(false);
      setChaptersList(response?.data?.chapters);
    } else {
      setChaptersList([]);
      toast.dismiss();
      toast(
        (t) => (
          <span className="toast-cont">
            <div>Please add new chapter to this subject</div>
            <div className="toastBtn">
              <button onClick={() => toast.dismiss(t.id)}>Close</button>
            </div>
          </span>
        ),
        { duration: 5000 },
      );
      setLoadingChapter(false);
    }
  };

  const getTopicsList = async (id) => {
    setLoadingTopic(true);
    const response = await axios.get(`${api.topic.list}${id}?page=1&limit=100`);
    if (response?.data?.message == "Topics found") {
      setTopicsList(response?.data?.topics);
      setLoadingTopic(false);
    } else {
      setTopicsList([]);
      toast.dismiss();
      toast(
        (t) => (
          <span className="toast-cont">
            <div>
              Failed to fetch topics, Please add new topics to this chapter
            </div>
            <div className="toastBtn">
              <button onClick={() => toast.dismiss(t.id)}>Close</button>
            </div>
          </span>
        ),
        { duration: 5000 },
      );
      setLoadingTopic(false);
    }
  };

  useEffect(() => {
    getChaptersList();
  }, []);

  useEffect(() => {
    ansSet.map((d) => {
      if (d.isAnswer === "true") {
        setTempAnsSt("false");
        setCorrectAnsSel(true);
      }
    });
  }, [ansSet]);

  return (
    <Box id="uq-cont">
      <Helmet>
        <title>DreamCatchr | Add Question</title>
        <meta name="description" content="Add Question" />
      </Helmet>
      <div>
        <Toaster position="top-center" reverseOrder={false} />
      </div>
      <div id="uq-left">
        <div id="uq-hd">Add Questions</div>
        <div id="uq-body">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: { xs: "column", lg: "row" },
              gap: 2,
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                Select Chapter
              </Typography>
              {loadingChapter ? (
                <Skeleton height={40} width={"100%"} />
              ) : (
                <TextField
                  select
                  value={selectedChapter?.id}
                  fullWidth
                  variant="outlined"
                >
                  {chaptersList?.map((item) => (
                    <MenuItem
                      onClick={() => {
                        setSelectedChapter(item);
                        getTopicsList(item?.id);
                      }}
                      value={item?.id}
                    >
                      {item?.chapter_name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Box>
            <Box sx={{ width: "100%" }}>
              <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                Select Topic
              </Typography>
              {loadingTopic ? (
                <Skeleton height={40} width={"100%"} />
              ) : !loadingTopic && selectedChapter === null ? (
                <TextField
                  disabled
                  fullWidth
                  select
                  label="Select A Chapter First"
                />
              ) : (
                <TextField
                  select
                  value={selectedTopic?.id}
                  fullWidth
                  variant="outlined"
                >
                  {topicsList?.map((item) => (
                    <MenuItem
                      onClick={() => {
                        setSelectedTopic(item);
                      }}
                      value={item?.id}
                    >
                      {item?.title}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Box>
          </Box>

          <Box mt={1.5}>
            <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
              Question
            </Typography>
            <TextField
              value={question}
              type="text"
              placeholder="Enter Title"
              onChange={(e) => {
                setQuestion(e.target.value);
              }}
              fullWidth
              variant="outlined"
            />
            {/* <MathQuillQuestionEdit value={question} setValue={setQuestion} /> */}
          </Box>

          <Box mt={1.5}>
            <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
              Question's Explanation (optional)
            </Typography>
            <Box
              id="rte-cont"
              sx={{
                border: "1px solid #aaa",
                borderRadius: 2,
                overflow: "hidden",
              }}
            >
              <RTEUpload setValue={setQuestionDescription} close={clearRTE} />
            </Box>
          </Box>

          <Box mt={1.5} sx={{ width: { xs: "100%", lg: "50%" } }}>
            <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
              Select Difficulty Level
            </Typography>
            <TextField
              select
              value={difficultyLevel}
              fullWidth
              variant="outlined"
              defaultValue={"Easy"}
            >
              {["Easy", "Medium", "Hard"].map((item) => (
                <MenuItem onClick={() => setDifficultyLevel(item)} value={item}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
          </Box>

          <Box mt={1.5}>
            <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
              Add Answers
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: { xs: "flex-start", lg: "space-between" },
                columnGap: 1,
                rowGap: 1,
                flexWrap: { xs: "wrap", lg: "nowrap" },
              }}
            >
              {/* <MathQuillAnswerEdit
                value={tempAnsKey}
                setValue={setTempAnsKey}
              /> */}

              <TextField
                sx={{mt: 0.5}}
                value={tempAnsKey}
                type="text"
                placeholder="Enter answer"
                onChange={(e) => {
                  setTempAnsKey(e.target.value);
                }}
                fullWidth
                variant="outlined"
              />

              {!correctAnsSel && (
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: tempAnsSt === "true" ? "success.main" : "#ddd",
                    color: tempAnsSt === "true" ? "#fff" : "#707070",
                    whiteSpace: 'nowrap',
                    px: 2,
                  }}
                  onClick={() => {
                    setTempAnsSt(tempAnsSt === "true" ? "false" : "true");
                  }}
                >
                  Correct Answer
                </Button>
              )}
              <Button variant="contained" onClick={addToAnsList}>
                Add
              </Button>
            </Box>
          </Box>

          <div id="uq-display-answer">
            {true &&
              ansSet.map((i, index) => {
                return (
                  <div className="uq-ans-set">
                    <div className="uq-ans-id">
                      <span>{index + 1}</span>
                    </div>

                    {/* <div className="uq-ans-value">
                      <div>{i.value}</div>
                    </div> */}

                    <div>
                      <MathQuillAnswerShow value={i.value} />
                    </div>

                    <div className="uq-ans-setts">
                      <button
                        className="uq-ans-del"
                        onClick={() => {
                          let data = ansSet.filter((k) => {
                            if (k.isAnswer === "true") {
                              setCorrectAnsSel(false);
                            }
                            return k.id != i.id;
                          });
                          setAnsSet(data);
                        }}
                      >
                        Delete
                      </button>

                      <button
                        className="uq-ans-editAns"
                        onClick={() => {
                          setPopUp(true);
                          setTempEditId({
                            id: i.id,
                            value: i.value,
                          });
                        }}
                      >
                        Edit
                      </button>

                      {popUp && (
                        <PopUpUQ
                          setPopUp={setPopUp}
                          onClose={() => {
                            setTempEditId();
                          }}
                          defaultValue={tempEditId.value}
                          updateAnswer={(val) => {
                            ansSet.filter((p) => {
                              return p.id === tempEditId.id
                                ? (p.value = val)
                                : null;
                            });
                          }}
                        />
                      )}

                      {i.isAnswer === "true" ? (
                        <span className="uq-ans-isAnswer">Correct answer</span>
                      ) : (
                        <span
                          className="uq-ans-setAnswer"
                          onClick={() => {
                            const filteredAnsSet = ansSet.filter((l) =>
                              l.id === i.id
                                ? (l.isAnswer = "true")
                                : (l.isAnswer = "false"),
                            );
                            setCorrectAnsSel(true);
                            setAnsSet(filteredAnsSet);
                          }}
                        >
                          Set as correct answer
                        </span>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>

          {ansSet.length > 1 && (
            <Box mt={1.5}>
              <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                Answer Explanation (optional)
              </Typography>
              <Box
                id="rte-cont"
                sx={{
                  border: "1px solid #aaa",
                  borderRadius: 2,
                  overflow: "hidden",
                }}
              >
                <RTEUpload setValue={setTempAnsDes} />
              </Box>
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              mt: 2,
              mb: 10,
            }}
          >
            <LoadingButton
              loading={submitting}
              onClick={handleSubmit}
              fullWidth={false}
              variant="contained"
            >
              Submit
            </LoadingButton>
          </Box>
        </div>
      </div>
    </Box>
  );
};

export default UploadQuestion;

const AnsCont = styled.div`
  justify-content: space-between;
  @media (max-width: 1100px) {
    justify-content: ${(props) =>
      !props.disp ? "flex-start" : "space-between"};
  }
`;

const AddBtn = styled.button`
  @media (max-width: 1100px) {
    margin-top: ${(props) => (!props.mg ? "0.5rem" : "0")};
    background-color: red;
    margin-left: 0.5rem;
  }
`;

const CorrectAns = styled.div`
  background-color: ${(props) => (props.bg === "true" ? "#6ac001" : "#eee")};
  color: ${(props) => (props.bg === "true" ? "white" : "black")};
`;

const Submit = styled.button`
  cursor: ${(props) => (props.cur ? "progress" : "pointer")};
`;
