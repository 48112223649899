import React, { useState } from "react";
import "../../../../Components/PopupUQ/popup-uq.scss";
import copyImg from "../../../../Images/Icons/content_copy.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";

const PopUpReport = ({ dat, showPopup }) => {
  return (
    <div id="report-popup-page">
      <div id="re-pop-del-box-cont">
        <div id="re-pop-del-box">
          <p>{dat.message}</p>
          <h4 id="report-h4-one">
            Issue submitted to technical team, If its critical, Please contact
            us
          </h4>
          <CopyToClipboard text={dat.refId}>
            <h5 id="report-h5-two">
              Ref Id: <span />
              {dat.refId}
              <img
                id="report-img-one"
                src={copyImg}
                alt="copy"
                onClick={() => {
                  toast.dismiss();
                  toast.success("copied to clipboard");
                }}
              />
            </h5>
          </CopyToClipboard>

          <div className="re-pop-del-hd">
            <button
              id="re-pop-del-close-btn"
              onClick={() => {
                showPopup(false);
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopUpReport;
