import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./account.scss";
import userPlaceholder from "../../../../Images/Icons/userPlaceholder.png";
import data from "../../../../Config/data";
import EditAccount from "../../EditAccout/edit_account";

const Account = ({ setAccSt }) => {
  const nav = useNavigate();
  const [ProfileEdit, setProfileEdit] = useState(false);

  const logout = () => {
    data.user_details = [];
    localStorage.clear("userData");
    localStorage.clear("subject_id");
    localStorage.clear("subjectDetails");
    localStorage.clear("isLogged");
    nav("../", { replace: true });
  };

  const chngProfileEdit = () => {
    nav("home/edit-account");
    setAccSt("closed");
  };

  return (
    <div className="profile-sml-cont">
      <div className="one">
        <div className="dp">
          <img src={userPlaceholder} alt="alt" />
        </div>
      </div>

      <div className="name">{data.user_details.name}</div>

      <div className="email">{data.user_details.email}</div>

      <button onClick={chngProfileEdit} className="edit-prof ok">
        Edit Profile
      </button>
      <button onClick={logout} className="logout">
        Logout
      </button>
    </div>
  );
};

export default Account;
