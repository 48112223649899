import { useState, useEffect } from "react";
import { StaticMathField } from "react-mathquill";
import "./index.scss";

const MathQuillAnswerShow = ({ value }) => {
  const [phone, setPhone] = useState(window.innerWidth < 1100 ? true : false);

  const changeWidth = (e) => {
    if (phone) {
      e.__controller.container[0].style.width = "100%";
    } else {
      e.__controller.container[0].style.maxWidth = "100%";
      e.__controller.container[0].style.minWidth = "60%";
    }
  };

  return (
    <StaticMathField
      className="mathquill-static-container"
      mathquillDidMount={changeWidth}
    >
      {value}
    </StaticMathField>
  );
};

export default MathQuillAnswerShow;
