import { StaticMathField } from "react-mathquill";
import "./index.scss";

const MathQuillQuestionShow = ({ value }) => {
  return (
    <StaticMathField
      className="mathquill-static-container"
      mathquillDidMount={(e) => {
        e.__controller.container[0].style.width = "100%";
        if (
          e.__controller.container[0].parentNode.parentNode.className ==
          "eq-chap-edit-indi-box"
        ) {
          e.__controller.container[0].style.backgroundColor = "transparent";
        } else console.log("noraml");
      }}
      style={{ textWrap: "wrap", whiteSpace: "wrap" }}
    >
      {value}
    </StaticMathField>
  );
};

export default MathQuillQuestionShow;
